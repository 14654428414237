<template>
  <div class="participant-list">
    <participant-item v-for="participant in sortedParticipants" :key="participant.id" :title="participant.name" :class="{ speaking: participant.speaking }">
      <template #icon>
        <div class="side-nav-collapse-icon">
          <volume-high-icon title="" class="speaking" v-if="participant.speaking" />
          <account-circle-icon title="" v-else />
        </div>
      </template>
      <template #right>
        <b-dropdown variant="link" right no-caret v-if="isModerator">
          <template slot="button-content">
            <dots-vertical-icon title="" class="option-icon" />
          </template>
          <b-dropdown-item @click="$emit('mute', participant.id)" class="text-danger">{{ $t("conference.muteAudio") }}</b-dropdown-item>
        </b-dropdown>
      </template>
    </participant-item>
  </div>
</template>

<script>
import ParticipantItem from "./ParticipantItem";
import AccountCircleIcon from "vue-material-design-icons/AccountCircle";
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical";
import VolumeHighIcon from "vue-material-design-icons/VolumeHigh";
import roles from "@/model/roles";
import { mapState } from "vuex";

export default {
  props: {
    participants: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  computed: {
    sortedParticipants() {
      if (!this.participants) {
        return [];
      }
      return this.participants
        .slice()
        .sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return 0;
        })
        .map((p, i) => {
          p.id = p.userId + "-" + i;
          return p;
        });
    },

    ...mapState({
      keycloak: state => state.authentication.keycloak
    }),

    isModerator() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.MODERATOR);
      } else {
        return false;
      }
    }
  },

  components: {
    AccountCircleIcon,
    DotsVerticalIcon,
    ParticipantItem,
    VolumeHighIcon
  }
};
</script>

<style lang="scss" scoped>
.participant-list {
  width: 100%;
  height: 100%;
  padding: 15px;
  right: 0;
  background: white;
}

.speaking {
  color: $vitu-green;
}
</style>
