<template>
  <div id="app" :class="[{ 'no-select': preventSelect }]">
    <navbar v-if="!isLoginPage && keycloak.authenticated" />
    <b-modal v-if="updateExists" id="new-version-info" class="flex-container" :visible="true" no-close-on-esc no-close-on-backdrop hide-header hide-footer size="sm" centered>
      <div class="center row">{{ $t("newVersion") }}</div>
      <div class="center">
        <b-button variant="primary" class="center" @click="refreshApp">
          {{ $t("refresh") }}
        </b-button>
      </div>
    </b-modal>
    <div :class="{ content: !isLoginPage }">
      <b-alert
        v-if="!isLoginPage && keycloak.authenticated"
        id="userAlert"
        :show="settings.alertActive"
        class="my-alert alert-fixed-top"
        :style="{ 'background-color': settings.alertColor, 'border-color': settings.alertColor, color: settings.alertTextColor }"
        dismissible
      >
        <bullhorn-outline /> {{ settings.alertText }}
      </b-alert>
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import config from "@/config/config";
// import update from "./mixins/update";
import BullhornOutline from "vue-material-design-icons/BullhornOutline";

import { mapState } from "vuex";

export default {
  // mixins: [update],
  data() {
    return {
      alertHeight: 0
    };
  },
  computed: {
    isLoginPage() {
      return this.$route.name === "login";
    },

    ...mapState({
      keycloak: state => state.authentication.keycloak,
      settings: state => state.settings.conferenceSettings
    }),

    preventSelect() {
      return config.PREVENT_SELECT;
    }
  },
  components: {
    Navbar,
    BullhornOutline
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: $navbar-height;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.extra-padding {
  padding-top: $navbar-height;
}
.flex-container {
  display: flex;
}
.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.row {
  margin-bottom: 1rem;
}
.alert-fixed-top {
  position: fixed;
  right: 0;
  z-index: 2000;
}
.my-alert {
  padding: 0.25rem 3.25rem 0.25rem 1.25rem;
  margin: 1.25rem !important;

  :deep(.close) {
    padding: 0.15rem 1.25rem;
  }
}
</style>

<style lang="scss">
@import "scss/styles.scss";
</style>
