<template>
  <div>
    <div v-if="!loading && !error">
      <div class="d-flex">
        <div class="w-100">
          <div class="form-group">
            <v-select class="multiple" multiple :options="schedulableCases" label="display" :placeholder="$t('pleaseSelect')" v-model="selectedCases" :close-on-select="false" />
          </div>
        </div>
        <div class="ml-2">
          <button ref="addButton" class="btn btn-secondary btn-add" :disabled="selectedCases == null || selectedCases.length === 0" @click="addCases">{{ $t("add") }}</button>
        </div>
      </div>
      <div class="list-item list-header">
        <b-row>
          <b-col sm="2">#</b-col>
          <!-- <b-col sm="2">{{ $t("clinicalCaseNumber") }}</b-col> -->
          <b-col sm="9">{{ $tc("worklist.patient", 1) }}</b-col>
          <!-- <b-col sm="2">{{ $t("fhir.birthDate") }}</b-col>
          <b-col sm="4">{{ $t("fhir.condition") }}</b-col> -->
        </b-row>
      </div>
      <div v-for="(entry, index) in assignedCases" :key="entry.id">
        <div class="list-item">
          <b-row>
            <b-col sm="2">{{ index + 1 }}</b-col>
            <!-- <b-col sm="2">{{ entry.id }}</b-col> -->
            <b-col sm="9">{{ entry.subject.display }}</b-col>
            <!-- <b-col sm="2">{{ entry.id }}</b-col>
            <b-col sm="4">{{ entry.id }}</b-col> -->
            <b-col sm="1" style="display: flex; justify-content: flex-end">
              <delete-icon class="delete-icon" @click="removeCase(entry)" title=""></delete-icon>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-3">
        <router-link target="_blank" :to="{ name: 'conference-moderator', params: { room: janusId } }">{{ $t("openDetailledCaseOverview") }} <open-in-new-icon /></router-link>
      </div>
    </div>
    <div v-else-if="error != null">{{ error }}</div>
    <spinner v-else line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" />
  </div>
</template>

<script>
import config from "@/config/config";
import { fetchResources, mapFhirResponse, submitResource, deleteResource } from "@molit/fhir-api";
import { updateTask, fetchStatuses, fetchSchedulableCases } from "@/api/worklist-api";
import { encounterTMBKonferenz, encounterTMBFall } from "@/templates/fhir-templates";

import OpenInNewIcon from "vue-material-design-icons/OpenInNew";
import fhirpath from "@/assets/js/fhirpath.min.js";
import { cloneDeep } from "lodash";
import DeleteIcon from "vue-material-design-icons/Delete";
import Spinner from "vue-simple-spinner";
import { mapState } from "vuex";

export default {
  props: {
    roomId: {
      type: Number,
      required: true
    },

    janusId: {
      type: Number
    },

    roomDate: {
      type: String
    },

    roomText: {
      type: String
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      schedulableCases: null,
      selectedCases: [],
      statuses: null,
      conferenceEncounter: null,
      assignedCases: []
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token,
      roles: state => state.authentication.keycloak.realmAccess.roles
    }),

    fhirBaseUrl() {
      return config.FHIR_URL;
    }
  },

  methods: {
    async fetchTmbEncounter() {
      try {
        const searchParams = {
          type: "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl|C93304",
          identifier: "http://molit.eu/fhir/vitu/sid/videoRoomId|" + this.roomId,
          _revinclude: "Encounter:part-of"
        };

        const FHIRPATH_MTB_CONFERENCE = "Encounter.where(type.coding.system='http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl' and type.coding.code='C93304').first()";
        const FHIRPATH_MTB_CASES = "Encounter.where(type.coding.system='http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl' and type.coding.code='C159520')";

        const resources = mapFhirResponse(await fetchResources(this.fhirBaseUrl, "Encounter", searchParams, this.token));

        if (resources != null && resources.length !== 0) {
          this.conferenceEncounter = fhirpath.evaluate(resources, FHIRPATH_MTB_CONFERENCE)[0];
          this.assignedCases = fhirpath.evaluate(resources, FHIRPATH_MTB_CASES);
        } else {
          const encounter = cloneDeep(encounterTMBKonferenz);
          encounter.identifier[0].value = this.roomId;
          encounter.period.start = this.roomDate;
          encounter.serviceType.text = this.roomText;
          this.conferenceEncounter = (await submitResource(this.fhirBaseUrl, encounter, this.token)).data;
        }
      } catch (e) {
        this.handleError(e, true);
      }
    },

    async fetchStatuses() {
      try {
        this.statuses = await fetchStatuses(this.fhirBaseUrl, this.token);
      } catch (e) {
        this.handleError(e, true);
      }
    },

    async fetchSchedulableCases() {
      try {
        this.schedulableCases = await fetchSchedulableCases(this.fhirBaseUrl, this.token);
      } catch (e) {
        this.handleError(e, true);
      }
    },

    async addCases() {
      try {
        const status = this.statuses.find(status => status.code != null && status.code.includes("case-review-announced"));

        for (let i = 0; i < this.selectedCases.length; i++) {
          const selectedCase = this.selectedCases[i];
          const encounter = cloneDeep(encounterTMBFall);
          encounter.subject.reference = "Patient/" + selectedCase.patientId;
          encounter.subject.display = selectedCase.display;
          encounter.partOf.reference = "Encounter/" + this.conferenceEncounter.id;
          encounter.episodeOfCare[0].reference = selectedCase.episodeOfCareId;
          this.loading = true;
          await submitResource(this.fhirBaseUrl, encounter, this.token);

          if (status) {
            await updateTask(this.fhirBaseUrl, this.token, selectedCase.task, status, null, null);
          }
        }
        this.reload();
      } catch (e) {
        this.handleError(e);
      }
    },

    async reload() {
      this.loading = true;
      this.schedulableCases = null;
      this.selectedCases = [];
      this.conferenceEncounter = null;
      this.assignedCases = [];
      await this.fetchTmbEncounter();
      await this.fetchSchedulableCases();
      this.loading = false;
    },

    async removeCase(encounter) {
      try {
        await deleteResource(this.fhirBaseUrl, encounter, this.token);
        this.assignedCases = this.assignedCases.filter(a => a.id !== encounter.id);
      } catch (e) {
        this.handleError(e);
      }
    },

    handleError(e) {
      this.error = e;
    }
  },

  components: {
    DeleteIcon,
    OpenInNewIcon,
    Spinner
  },

  async created() {
    await this.fetchTmbEncounter();
    await this.fetchSchedulableCases();
    await this.fetchStatuses();
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.list-header {
  font-weight: 500;
}

.list-item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $border-color;
}

.delete-icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  cursor: pointer;
  & :hover {
    color: rgba(0, 0, 0, 0.6);
  }
}

.btn-add {
  background: white;

  &:disabled {
    background: white;
  }
}
</style>
