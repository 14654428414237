var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.consentDialogScreen)?_c('div',{staticClass:"consent-dialog"}):_vm._e(),_c('div',{staticClass:"conference"},[_c('div',{staticClass:"main-panel"},[(_vm.demo)?_c('div',{staticClass:"info-box"},[_c('i18n',{attrs:{"path":"demo.room"}},[_c('a',{attrs:{"href":_vm.url,"place":"url"}},[_vm._v(_vm._s(_vm.url))])])],1):_vm._e(),(_vm.roomName)?_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.roomName)),(_vm.roomDate && _vm.showRoomDate)?_c('span',[_vm._v(" - "+_vm._s(_vm.$d(new Date(_vm.roomDate), "long")))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"video-conference-container"},[_c('div',{class:[{ 'video-conference': !_vm.fullScreenParticipant }],style:([
            {
              display: _vm.fullScreenParticipant ? '' : 'grid',
              'max-width': _vm.fullScreenParticipant ? '175px' : '',
              'max-height': _vm.fullScreenParticipant ? 'calc(100vh - 120px' : '',
              'overflow-y': _vm.fullScreenParticipant ? 'auto' : '',
              flex: !_vm.fullScreenParticipant ? 1 : '',
              'grid-template-columns': !_vm.fullScreenParticipant ? 'repeat(auto-fit, minmax(' + _vm.getMinSizeForVideoElement() + ', 0.5fr))' : ''
            }
          ])},[(_vm.localParticipant)?_c('video-conference-item',{style:([{ display: _vm.video ? '' : 'none' }]),attrs:{"mirrored":true,"muted":true,"participant":_vm.localParticipant,"ratioX":_vm.ratioX,"ratioY":_vm.ratioY,"cutVideoStreams":_vm.cutVideoStreams,"audio":_vm.audio,"video":_vm.video},on:{"toggleVideo":_vm.toggleVideo,"toggleAudio":_vm.toggleAudio}}):_vm._e(),_vm._l((_vm.filteredRemoteParticipants),function(remoteParticipant){return _c('video-conference-item',{key:remoteParticipant.id,style:([{ display: _vm.shouldDisplayParticipant(remoteParticipant) ? '' : 'none' }]),attrs:{"participant":remoteParticipant,"ratioX":_vm.ratioX,"ratioY":_vm.ratioY,"cutVideoStreams":_vm.cutVideoStreams,"bitrate":_vm.bitrate}})})],2),(_vm.fullScreenParticipant)?_c('div',{staticClass:"fullscreen-element",style:([{ 'margin-left': _vm.showMarginOnFullScreenParticipant ? '' : '0' }])},_vm._l((_vm.fullScreenParticipants),function(p){return _c('video-conference-item',{key:p.id,attrs:{"mirrored":false,"muted":true,"fullScreen":true,"participant":p}})}),1):_vm._e()])]),(_vm.room)?_c('control-panel',{staticClass:"control-panel",attrs:{"showEntries":_vm.showEntries,"entries":_vm.entries,"room":_vm.room,"consentDialogScreen":_vm.consentDialogScreen,"audio":_vm.audio,"video":_vm.video,"speaking":_vm.speaking,"devices":_vm.devices,"screenShared":_vm.screenShared,"fullScreenParticipant":_vm.fullScreenParticipant,"currentRoundTripTime":_vm.currentRoundTripTime,"numberOfVideos":_vm.numberOfVideos,"maxNumberOfVideos":_vm.maxNumberOfVideos,"participantOnly":_vm.participantOnly},on:{"shareScreen":_vm.shareScreen,"unshareScreen":_vm.unshareScreen,"leave":_vm.goToHome,"toggleAudio":_vm.toggleAudio,"toggleVideo":_vm.toggleVideo,"audioDeviceChanged":_vm.onAudioDeviceChanged,"publish":_vm.publish,"mute":_vm.muteAudio}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }