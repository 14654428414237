const en = {
  title: "VITU",
  data: {
    loading: "Loading..."
  },
  login: {
    title: "VITU - Virtual Tumor Board",
    subtitle: "Please sign in to participate in a conference.",
    text: "Please sign in to participate in a conference.",
    buttonText: "Sign in",
    imprintLink: "https://molit.eu/en/imprint/"
  },
  home: {
    home: "Home",
    backToHome: "Back to home page"
  },
  worklist: {
    organization: "Organization | Organizations",
    worklist: "Working list | Working lists",
    caseId: "Case Id",
    patient: "Patient | Patients",
    patientDetails: "Patient details",
    existingPatients: "Existing patients",
    noPatients: "No patients available",
    loadingData: "Loading data",
    checkIfPatientExists: "Checking if patient already exists",
    creatingPatient: "Creating patient",
    savingData: "Saving data",
    searchPatient: "Search patient",
    editPatient: "Edit patient",
    date: "Date",
    creationDate: "Creation date",
    birthDate: "Birth date",
    diagnosis: "Diagnosis",
    caseStatus: "Case status",
    entry: "Entry | Entries",
    filterEntries: "Filter entries",
    entriesPerPage: "Entries per page",
    noEntriesFound: "No cases found.",
    "in-progress": "current",
    completed: "completed",
    cancelled: "canceled",
    statusDescription: "Status description",
    cases: "Cases",
    details: "Details",
    case: "Case",
    masterData: "Master data",
    treatmentHistory: "Treatment History",
    reports: "Diagnostic Reports",
    approve: "Approve",
    noItemSelected: "No item selected",
    currentStatus: "Current status",
    changeStatus: "Change stetus",
    statusChangedSuccessfully: "Status has been changed successfully",
    reasonForCancellation: "Reason for cancellation",
    reasonCode: {
      deceased: "Patient deceased",
      other: "Other"
    },
    lastUpdated: "Last updated",
    reason: "Reason",
    status: "Status",
    statusCode: {
      WAITING_FOR_APPROVAL: "Waiting for approval",
      INITIATE_MOLECULAR_DIAGNOSTIC: "Initiate diagnostic",
      PATIENT_DECLARATION_OF_CONSENT: "Declaration of consent",
      COMMISSION_MOLECULAR_DIAGNOSTIC: "Commission molecular diagnostic",
      SEND_SPECIMEN: "Send specimen",
      WAIT_FOR_REPORT: "Waiting for report",
      WAIT_FOR_CASE_DISCUSSION: "Wait for case discussion",
      DISPATCH_NORMAL_TISSUE: "Dispatch normal tissue",
      REQUEST_TUMOR_TISSUE: "Request tumor tissue",
      DISPATCH_TUMOR_TISSUE: "Dispatch tumor tissue",
      ASSIGN_TO_TUMOR_CONFERENCE: "Assign to tumor conference",
      COMPLETE_RECOMMENDATION: "Complete recommendation",
      COMPLETED: "Completed",
      CANCELED: "Canceled"
    },
    successfullyAddedCase: "The case has been successfully added to the worklist.",
    successfullyEditedCase: "The case was edited successfully.",
    deactivatedStateInfo: `Changes of the states in the administration area can affect already assigned states, which are deactivated afterwards.
    These are highlighted in red. A status change of the case is recommended.`,
    deleteCase: "Delete case",
    deleteCaseConfirmation: "Do you really want to delete the case for the patient '{patient}'?",
    deleteSuccessful: "The case was deleted successfully.",
    openDataDonation: "Open Donation of Data",
    hintSearchDate: "The date you are looking for must be specified in one of the following patterns: YYYY, YYYY-MM, YYYY-MM-DD"
  },
  planner: {
    conferencePlanner: "Planner",
    conferenceModerator: "Conference View",
    schedulableCases: "Schedulable cases",
    planner: "Planner",
    searchCase: "Search case",
    addCase: "Add case",
    searchConference: "Search conference",
    document: "Document",
    casesAssigned: "case assigned | cases assigned",
    noSchedulableCases: "No schedulable cases",
    scheduleNewConference: "Schedule new conference",
    enterConferenceName: "Enter conference name",
    description: "Description (optional)",
    time: "Time",
    cancel: "Cancel",
    create: "Create",
    case: "Case | Cases",
    noCases: "There are no cases available",
    selectACase: "Please select a case",
    caseAssignmentSuccessful: "The case was successfully assigned to the conference",
    caseAlreadyExists: "Case already exists",
    caseAlreadyExistsDescription: "The case was already assigned to this conference.",
    deleteConference: "Delete conference",
    hintConferenceInPast: "The time for the conference is in the past. Are you sure you want to create the conference?",
    createAppointmentSeries: "Create appointment series",
    cloneConference: "Clone conference",
    comments: "Comments",
    patientInformation: "",
    results: "Results",
    therapyRecommendation: "Therapy recommendation",
    hintName: "Please name the conference.",
    hintTime: "Please enter a time.",
    hintDate: "Please enter a date."
  },
  conference: {
    conference: "Conference",
    videoConference: "Video conference",
    shareScreen: "Share screen",
    shareScreenDescription: "You can share your screen by clicking on the button. You then can select the screen you want to share.",
    unshareScreen: "Unshare screen",
    presentationView: "Presentation View",
    conferenceView: "Conference View",
    caseData: "Case data",
    searchDocument: "Search document",
    nextCase: "Next case",
    presentation: "Presentation",
    muteAudio: "Mute",
    unmuteAudio: "Unmute",
    muteVideo: "Mute video",
    unmuteVideo: "Unmute video",
    participants: "Participants",
    participantSpeaking: "Participant is speaking",
    participantNotSpeaking: "Participant is not speaking",
    leaveConference: "Leave conference",
    enterConference: "Enter conference",
    scheduledConferences: "Scheduled conferences",
    otherConferences: "Other rooms",
    roomNumber: "No.",
    initializing: "Room is initialized",
    tutorial: "Click on the button to enter the conference. In order to participate in the conference, you have to give your browser the permission to use the microphone and camera.",
    noEntries: "There are no entries assigned to this conference.",
    backToOverview: "Back to overview",
    notAllowed: "You don't have permission to enter this conference."
  },
  documentation: {
    documentation: "Protocol",
    existingProtocols: "Existing protocols",
    protocol: "Protocol",
    newProtocol: "New protocol",
    selectPatient: "Select patient",
    selectPatientInfo: "Please select a patient by using the button.",
    name: "Name",
    noProtocols: "No protocols available.",
    informationTumorboard: "Information tumor board",
    date: "Date",
    participants: "Participants",
    addParticipant: "Add participant",
    followUp: "Follow-up recommended",
    followUpReason: "Reason for follow-up",
    anamnesis: "Anamnesis",
    firstDiagnosis: "First diagnosis",
    encounterId: "Tumor board case ID",
    therapyRecommendation: "Therapy recommendation",
    addTherapyRecommendation: "Add therapy recommendation",
    detectedMutations: "Detected variants",
    selectReport: "Select report",
    selectReportInfo: "Please select a report by using the button.",
    reportDate: "Report date",
    reportId: "Report ID",
    laboratory: "Laboratory",
    recommendedStart: "Recommended start",
    therapyRecommendationReason: "Grundlage der Therapieempfehlung",
    somaticVariants: "Somatic variants (SNVs)",
    copyNumberVariants: "Copy number variants (CNVs)",
    structuralVariants: "Structural variants",
    noVariantsFound: "No variants found in this category.",
    reportInformation: "Report information",
    addTherapyRecommendationInfo: "You can use the button to add one ore more therapy recommendations.",
    noProtocolsFound: "No protocols found.",
    searchProtocol: "Search protocol",
    signature: "Signature | Signatures",
    evidenceLevels: "Evidence levels"
  },
  dateInput: {
    day: "Day",
    month: "Month",
    year: "Year",
    error: {
      title: "Error",
      notANumber: "Please enter a number",
      monthNeeded: "Please enter month",
      yearNeeded: "Please enter year",
      dateNotValid: "The date is not valid"
    }
  },
  navbar: {
    imprint: "Imprint",
    profile: "Profile",
    signOut: "Sign out"
  },
  chat: {
    chat: "Chat",
    writeMessage: "Write a message...",
    connecting: "Connecting...",
    inputHelp: "{enter} to send, {shift}+{enter} for new line",
    onTyping: "is typing"
  },
  fhir: {
    assertedDate: "Feststellungsdatum",
    clinicalStatus: "Clinical Status",
    issued: "Date",
    status: "Status",
    category: "Category",
    effectiveDateTime: "Date",
    result: "Result",
    date: "Date",
    gender: "Sex",
    relationship: "Relationship",
    deceasedAge: "Deceased age",
    condition: "Condition",
    reasonCode: "Reason",
    taken: "Taken",
    code: "Code",
    outcome: "Outcome",
    reason: "Reason",
    title: "Title",
    familyName: "Family name",
    givenName: "Given name",
    birthDate: "Birth date",
    male: "male",
    female: "female",
    other: "other",
    unknown: "unknown",
    diverse: "diverse",
    generalPractitioner: "General Practitioner"
  },
  demo: {
    welcome: "Welcome to the demo of VITU! For more information visit {url}.",
    room: "This room is for demo purposes only. To invite other participants to this room, please share this link: {url}"
  },
  roles: {
    roles: "Roles",
    moderator: "Moderator",
    administrator: "Administrator",
    caseManager: "Case Manager",
    freigeber: "Approver",
    participant: "Participant"
  },
  admin: {
    account: "Account",
    organization: "Organization | Organizations",
    addOrganization: "Add organization",
    editOrganization: "Edit organization",
    existingOrganizations: "Existing organizations",
    searchOrganization: "Search organisation",
    partOf: "",
    updatedOrganizationSuccessfully: "The changes were successfully applied",
    Address: {
      line: "line",
      postalCode: "Postal Code",
      state: "State",
      city: "City",
      country: "Country"
    },
    description: "Description",
    user: "User",
    userList: "User list",
    addUser: "Add user",
    usernameAlreadyPresent: "The username '{username}' is already taken.",
    emailAlreadyPresent: "There is already a user with the email '{email}'.",
    deleteUser: "Delete user",
    deleteUserConfirmation: "Do you really want to delete the user '{user}'?",
    deleteUserSuccessful: "The user was deleted successfully.",
    createUserSuccessful: "The user was created successfully.",
    editUserSuccessful: "The user was saved successfully.",
    editUser: "Edit user",
    adminArea: "Admin area",
    statusList: "Status list",
    addStatus: "Add status",
    editStatus: "Edit status",
    statusAlreadyExists: "A status with this name already exists.",
    conferenceSettings: "Conference settings",
    bitrate: "Video quality",
    aspectRatio: "Aspect ratio",
    save: "Save",
    saveSuccessful: "Successfully saved",
    statusListDescription: "You can select the states from the list and deactivate unused states. You can use drag&drop to place the states in an order of your choice. Then click save to apply the changes.",
    statusListDescriptionHint: `Please note that a change applies to all tumor conferences; states that have already been assigned and are subsequently deactivated are highlighted in color in the worklist 
    after the change has been saved. A status change of the case is recommended.`,
    active: "Active",
    inactive: "Inactive",
    showInactiveStatuses: "Show inactive statuses",
    noInactiveStatusesFound: "No inactive statuses found.",
    persistentRoomEnabled: "Persistent room enabled",
    persistentRoomName: "Persistent room name",
    cutVideoStreams: "Cut video streams",
    bitrates: {
      low: "Low",
      medium: "Medium",
      high: "High",
      veryHigh: "Very high",
      unlimited: "Unlimited"
    },
    showDateTimeInTitle: "Show date in title",
    turnUrl: "STUN/TURN Url",
    turnUser: "STUN/TURN User",
    turnSecret: "STUN/TURN Password",
    noOrganizations: "No organizations could be found.",
    createNotification: "Create notification",
    showNotification: "Show notification to all users",
    deleteOrganization: "Organisation löschen",
    deleteOrganizationConfirmation: "Do you really want to delete the organization '{organization}'?",
    deleteOrganizationSuccessful: "The organization was deleted successfully."
  },
  error: {
    errorOccurred: "An error occurred",
    warningOccurred: "Warning",
    unauthorized: "You are not authorized to execute this operation.",
    pageNotFound: "Page not found",
    pageNotFoundDescription: "The requested page could not be found. Please make sure that you entered the address correctly.",
    noConnectionToChat: "The connection to the chat could not be established.",
    clinicalDataNotAvailable: "Clinical data can only be accessed from within the organization."
  },
  firstName: "First name",
  lastName: "Last name",
  username: "User name",
  password: "Password",
  passwordConfirmation: "Password confirmation",
  passwordCriteria: "The password needs to meet the following criteria: minimum of 8 characters, 1 uppercase letter, 1 lowercase letter and 1 digit.",
  passwordsNotMatching: "The passwords don't match.",
  email: "Email",
  phone: "Phone",
  country: "Country",
  city: "City",
  postalCode: "Postal code",
  name: "Name",
  contact: "Contact",
  address: "Address",
  street: "Street",
  houseNumber: "House Number",
  organization: "Organization",
  parentOrganization: "Parent organization",
  organizationInfo: "The organization that this organization is a part of",
  noEntriesFound: "No entries found",
  patientId: "Patient ID",
  clinicalCaseNumber: "Case number",
  sex: "Sex",
  male: "male",
  female: "female",
  unknown: "unknown",
  other: "diverse",
  pleaseSelect: "Please select",
  cancel: "Cancel",
  notes: "Notes",
  collapse: "Collapse",
  uncollapse: "Open",
  add: "Add",
  hint: "Note",
  search: "Search",
  noInformation: "no information",
  print: "Print",
  yes: "Yes",
  no: "No",
  clinicalCase: "Case",
  addClinicalCase: "Add case",
  editClinicalCase: "Edit case",
  object: "Object",
  noAuditEvents: "There are no logs available",
  action: "Action",
  outcome: "Outcome",
  auditLog: "Audit log",
  type: "Type",
  subtype: "Sub-Type",
  new: "New",
  addPatient: "Add new patient",
  patientIdInfo: "The id the patient has in the hospital information system (his).",
  caseIdInfo: "The id the case has in the hospital information system (his).",
  icd10Info: "Please enter IDC10 code.",
  setNewPassword: "Set new password",
  hintTemporaryPassword: "This is a temporary password. The user has to set a new password on the next login.",
  creatingPatientFailed: "Creating the patient failed",
  patientCreatedSuccessfully: "The patient has been created successfully.",
  patientUpdatedSuccessfully: "The changes were successfully applied",
  selectAnotherPatient: "Select another patient",
  placeholderDate: "YYYY-MM-DD",
  changePassword: "Change password",
  close: "Close",
  registerCase: "Register case",
  courseOfDisease: "Course of disease",
  inclusionCriteria: "Indication for MTB",
  icd10: "ICD-10",
  icdo3: "ICD-O-3",
  uicc: "Initial UICC Stage",
  dischargeSummary: "Discharge summary",
  mostRecentDischargeSummary: "Most recent discharge summary",
  additionalDischargeSummary: "Additional discharge summary",
  additionalDocument: "Additional document",
  addAdditionalDocument: "Add additional document",
  addDocuments: "Add documents",
  document: "Document | Documents",
  chooseFile: "Choose file",
  therapyStartDate: "Start date",
  therapyEndDate: "End date",
  therapyType: "Therapy type",
  dateOfDiagnosis: "Date of first diagnosis",
  browse: "Browse",
  dataDonation: "Data donation",
  consentForDataDonation: "Consent for data donation present",
  diagnosticReport: "Diagnostic report | Diagnostic reports",
  pathologyReport: "Pathology report",
  addTherapy: "Add therapy",
  pleaseSelectPatient: "Please select the patient you want to register.",
  dateInfo: "You can enter year, year and month or year, month and day.",
  medication: "Medication",
  currentStatus: "Current status",
  ecogScore: "ECOG Score",
  currentEcogScore: "Current ECOG Score",
  consent: "Consent",
  tumorBoard: "Tumor Board",
  discussionInterSite: "Discussion in the inter-site Tumour Board",
  tnm: "TNM",
  initialTnm: "Initial TNM",
  copyToken: "Copy token",
  tokenSuccessfullyCopied: "The token has been successfully copied.",
  message: "Message",
  period: "Period",
  performedDiagnostics: "Performed diagnostics",
  options: "Options",
  furtherInformation: "Further information",
  comment: "Comment | Comments",
  relevantSecondaryDiagnoses: "Relevant secondary diagnoses",
  instantOfTime: "Instant of time",
  molecularTargetStructure: "Molecular target structure",
  evidence: "Evidence",
  evidenceLevel: "Evidence level",
  treatmentContext: "Treatment context",
  indicationInfo: "Please select at least one indication. It is possible to select multiple indications.",
  caseRegistrationInformation:
    "Fields marked with an asterisk (*) are mandatory for the final case registration. You can still save the registration without filling out all mandatory fields. Before registering the case, please check that only the data required for the case discussion has been provided.",
  addAdditionalDischargeSummary: "Add additional discharge summary",
  relevantPathologyReport: "Relevant Pathology report",
  additionalReport: "Additional report",
  addAdditionalReport: "Add additional report",
  positive: "Positive",
  negative: "Negative",
  "microsatellite instability (msi)": "MSI",
  "microsatellite stable (mss)": "MSS",
  showVideo: "Show video",
  enableAudioInitially: "Enable audio initially",
  recommendationCategory: "Recommendation category",
  participantList: "Participant list",
  moderationPanel: "Moderation panel",
  reallyLeaveConference: "Do you really want to leave the conference?",
  pleaseConfirm: "Please confirm",
  openCaseOverview: "Open case overview",
  openDetailledCaseOverview: "Open detailled case overview",
  caseOverviewDescription: "You can open the case overview by clicking on the button. The case overview will be opened in a new window.",
  caseList: "Case list",
  settings: "Settings",
  microphone: "Microphone",
  fullscreen: "Fullscreen",
  videoNewTab: "Open in new tab",
  addAll: "Add all",
  deleteAll: "Delete all",
  participant: "Participant | Participants",
  daily: "Daily",
  addComment: "Add comment",
  deleteComment: "Delete comment",
  newestFirst: "Newest first",
  oldestFirst: "Oldest first",
  caseOverview: "Case Overview",
  commentVerb: "Comment",
  openCaseList: "Open Case List",
  molecularDiagnostic: "Molecular Diagnostic",
  editPatientInformation: "Edi Patient Information",
  generateProtocol: "Generate recommendation log",
  recommendationProtocol: "Recommendation Protocol",
  uploadRecommendationProtocol: "Upload recommendation protocol",
  cannotDeleteYourself: "You cannot delete yourself.",
  userFromFederationInfo: "Some fields cannot be edited because the user comes from a domain (LDAP / Active Directory).",
  existingUsers: "Existing users",
  selectAll: "Select All",
  finalizeCaseRegistration: "Finalize Case Registration",
  "not tested": "Not Tested",
  noComments: "There are no comments yet.",
  copyEmails: "Copy all email addresses",
  emailsSuccessfullyCopied: "All email addresses have been copied to the clipboard.",
  noMoreVideoStreamsAllowed: "No more video streams allowed",
  maxNumberOfVideos: "Max. Video Streams",
  indicationWarning: "Please select at least one indication.",
  copyUsers: "Copy users' names",
  usersCopiedSuccessfully: "User data has been copied successfully.",
  upload: "Document Upload",
  addCaseInformation: "Add case information",
  chooseFiles: "Choose file(s)",
  file: "File | Files",
  uploadSuccessful: "The file(s) have/has been uploaded successfully.",
  uploadHint: "Please leave a short description of the case in the comment field and state the patient's managing organization.",
  infoPage: "Information page",
  detailedInformation: "Detailed informationen",
  addEntry: "Add entry",
  study: "Study | Studies",
  uploadCaseInfo: "Import case information",
  participantOnly: "Enter as participant only",
  dialIn: "Dial in",
  newVersion: "New version available!",
  refresh: "Refresh",
  patientConsent: {
    consentTitle: "Consent to the use of patient data for medical research purposes",
    datenspende: "Collection, processing and scientific use of my patient data as described in the Patient Information",
    kontaktaufnahme_Zusatzbefunde: "Possibility of renewed contact (additional medical findings)",
    kontaktaufnahme_wissenschaftlich: "Possibility to be contacted again (scientific questions)",
    datenspendeDetail:
      "This includes </br> 1.) the processing and use of my patient data for medical research Administration of the name and other directly identifying data (coding) as described in the Patient Information (see points 1.1 and 1.2). </br> 2.) the scientific analysis and use of my coded patient data by third parties such as other clinics, universities, institutes, research companies; this may also include transfer for research projects abroad if European data protection law applies in these countries or the European Commission has confirmed an adequate level of data protection. I will not be involved in any commercial benefit from the research. Furthermore, prior to disclosure to researchers outside my treating institution, the internal identifier will be further replaced by a new combination of characters. </br> 3) the possibility of merging my patient data with data in databases of other research partners. The prerequisite is that I have also agreed to this use with the relevant research partners. </br> I consent to the collection, processing, storage and scientific use of my patient data as described in points (1) to (3) of the declaration of consent and point 1 of the patient information.",
    kontaktaufnahme_Zusatzbefunde_Detail: "I agree that I may be contacted again to be informed about additional medical findings (see point 2.2 of the patient information).",
    kontaktaufnahme_wissenschaftlich_Detail:
      "I agree that I may be contacted again to provide additional information relevant to scientific questions, to be informed about new research projects/studies, if necessary, and/or to obtain my consent to link my patient data with medical information from other databases (see point 2.1 of the patient information).",
    showMore: "show more",
    showLess: "show less",
    noInfo: "No information about consent is available",
    consentAvailable: "Consent to the use of patient data for medical research purposes has been given",
    support:
      'Clicking on "Open data donation" opens the data donation module. The consent points selected by the patient must be stored there. Clicking on "Confirm data donation" digitally transmits the consent. After saving, please click "OK", this will take you back to the patient page. Please wait until the process is complete so that all data can be transferred properly. {br}{br}If technical problems occurred during the transmission of data donation information or if incorrect information was transmitted by mistake, please contact the MOLIT Institute via support@molit.eu.',
    consentPresent: "consent data donation present"
  },
  patientAddress: "Patient's address",
  masterData: "Master data",
  reallyDelete: "Do you really want to delete this resource?",
  deleteConference: "Delete conference",
  reallyDeleteConference: "Do you really want to delete this conference? This action cannot be undone!",
  conferenceDeleteSuccessful: "The conference has been successfully deleted.",
  import: "Import",
  importMolecularReport: "Import molecular report",
  deleteReport: "Delete report",
  reallyDeleteReport: "Do you really want to delete this report? This action cannot be undone!",
  editDiagnosis: "Edit diagnosis",
  editIndication: "Edit indication",
  editDiagnostics: "Edit diagnostics",
  editFurtherInformation: "Edit further information",
  addReason: "Add reason",
  prio: "Prio",
  activeSubstance: "Active substance",
  export: "Export",
  externalCaseId: "External case id",
  serviceRequest: "Service Request",
  showOnlyDataDonation: "Show only data donation patients",
  value: "Value",
  edit: "Edit",
  ingredient: "Ingredient",
  noMatchingOptions: "Sorry, no matching options",
  otherReason: "Other reason",
  dateAndTime: "date and time",
  noPermissionsTitle: "No permissions",
  noPermissionsDscrp: "There are no permissions assigned to this account. Please contact the administrator.",
  validationMsg: {
    empty: "Please fill out this field",
    postalCode: "The input must match the required format",
    gender: "Please select the gender",
    birthDate: "Please select a birth date",
    email: "Please enter an email address with a valid format",
    password: "Please enter a password that matches the requested format."
  }
};

export default en;
