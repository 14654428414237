<template>
  <div class="pt-2">
    <h5 id="anchor-patient">{{ $tc("worklist.patient") }}</h5>
    <div v-if="patient">
      <div class="form-row">
        <label class="col-md-3 col-form-label">{{ $t("name") }}</label>
        <label class="col-md-9 col-form-label">{{ patientLastName }}, {{ patientFirstName }}</label>
      </div>
      <div class="form-row">
        <label class="col-md-3 col-form-label">{{ $t("worklist.birthDate") }}</label>
        <label class="col-md-9 col-form-label">{{ $d(new Date(patient.birthDate)) }}</label>
      </div>
      <div class="form-row">
        <label class="col-md-3 col-form-label">{{ $t("sex") }}</label>
        <label class="col-md-9 col-form-label">{{ $t(patient.gender) }}</label>
      </div>
      <div class="text-right" v-if="task">
        <router-link class="btn btn-secondary" :to="{ name: 'patient-edit', params: { id: patient.id }, query: { redirect: 'clinical-case', taskId: task.id } }">{{ $t("worklist.editPatient") }}</router-link>
      </div>
    </div>
    <hr />
    <div>
      <div v-if="showCaseRegistrationButton">
        <router-link class="btn btn-primary" :to="{ name: 'clinical-case-edit', params: { id: task.id } }">{{ $t("addCaseInformation") }}</router-link>
      </div>
      <div v-else>
        <h5 id="anchor-inclusion-criteria">{{ $t("inclusionCriteria") }}</h5>
        <div v-if="!editIndication">
          <div class="form-row" v-for="item in trueIndicationItems" :key="item.linkId">
            <!-- <label class="col-md-3 col-form-label">{{ getAnswerFromItem(item) }}</label> -->
            <label class="col-md-3 col-form-label"></label>
            <label class="col-md-9 col-form-label">{{ item.text }}</label>
          </div>
          <div class="text-right">
            <button type="button" disabled @click="editIndication = true" class="btn btn-secondary">{{ $t("editIndication") }}</button>
          </div>
        </div>
        <div v-if="editIndication"></div>
        <hr />
        <h5 id="anchor-diagnosis">{{ $tc("worklist.diagnosis") }}</h5>
        <div v-if="!editDiagnosis">
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $t("icd10") }}</label>
            <label class="col-md-9 col-form-label">{{ icd10 }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $t("subType") }}</label>
            <label class="col-md-9 col-form-label">{{ icdo3 }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $t("dateOfDiagnosis") }}</label>
            <label class="col-md-9 col-form-label">{{ conditionDate }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $t("uicc") }}</label>
            <label class="col-md-9 col-form-label">{{ uicc }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $t("initialTnm") }}</label>
            <label class="col-md-9 col-form-label">
              {{ tValue }}
              {{ nValue }}
              {{ mValue }}
            </label>
          </div>
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $t("currentStatus") }}</label>
            <label class="col-md-9 col-form-label">{{ currentStatus }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $t("currentEcogScore") }}</label>
            <label class="col-md-9 col-form-label">{{ ecogScore }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-3 col-form-label">{{ $tc("relevantSecondaryDiagnoses") }}</label>
            <label class="col-md-9 col-form-label" v-if="condition && condition.note">{{ condition.note.map(c => c.text).join(", ") }}</label>
          </div>
          <div class="text-right">
            <button type="button" @click="editDiagnosis = true" class="btn btn-secondary">{{ $t("editDiagnosis") }}</button>
          </div>
        </div>
        <div v-if="editDiagnosis">
          <div v-if="loadingDiagnosis">
            <spinner class="spinner mt-2" line-fg-color="#148898" line-bg-color="#99bfbf" size="large" :speed="1.5" />
          </div>
          <div class="form-group form-row" v-else>
            <label class="col-md-3 col-form-label" for="icd10">{{ $t("worklist.diagnosis") }}*</label>
            <div class="col-md-9">
              <concept-select
                :fhirBaseUrl="fhirBaseUrl"
                resourceName="ValueSet"
                url="http://molit.eu/fhir/ValueSet/icd-10-gm-c-II"
                :showCode="true"
                :showBorder="true"
                :searchInputPlaceholder="$t('search')"
                :token="token"
                :required="true"
                :expandValueSet="false"
                @error="$emit('error')"
                v-model="formData.icd10"
              />
            </div>
          </div>
          <div class="form-group form-row" v-if="icdo3list && icdo3list.length">
            <label class="col-md-3 col-form-label" for="subType">{{ $t("subtype") }}*</label>
            <div class="col-md-9">
              <v-select id="subType" required :options="icdo3list" v-model="formData.subType" :placeholder="icdo3list && icdo3list.length ? $t('pleaseSelect') : '-'" label="display">
                <template #option="option">{{ option.display }}</template>
                <template #no-options>{{ $t("noEntriesFound") }}</template>
              </v-select>
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="date-of-diagnosis">{{ $t("dateOfDiagnosis") }}*</label>
            <div class="col-md-9">
              <div>
                <date-input required v-model="formData.dateOfDiagnosis" :validation="false" :yearPlaceholder="$t('dateInput.year')" :monthPlaceholder="$t('dateInput.month')" :dayPlaceholder="$t('dateInput.day')" />
                <small class="form-text text-muted">{{ $t("dateInfo") }}</small>
              </div>
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="diagnosis">{{ $t("uicc") }}*</label>
            <div class="col-md-9">
              <v-select :options="optionsUicc" v-model="formData.uicc" label="display" :reduce="option => option.code" :placeholder="$t('pleaseSelect')">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!formData.uicc" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="tnm">{{ $t("initialTnm") }}</label>
            <div class="col-md-9">
              <div class="form-row">
                <div class="col">
                  <v-select :options="optionsT" v-model="formData.t" label="display" :reduce="option => option.code" :placeholder="`T - ${$t('pleaseSelect')}`"></v-select>
                </div>
                <div class="col">
                  <v-select :options="optionsN" v-model="formData.n" label="display" :reduce="option => option.code" :placeholder="`N - ${$t('pleaseSelect')}`"></v-select>
                </div>
                <div class="col">
                  <v-select :options="optionsM" v-model="formData.m" label="display" :reduce="option => option.code" :placeholder="`M - ${$t('pleaseSelect')}`"></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9"><hr /></div>
          </div>
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="current-status">{{ $t("currentStatus") }}*</label>
            <div class="col-md-9">
              <v-select id="current-status" required :options="optionsStatus" label="display" :placeholder="$t('pleaseSelect')" v-model="formData.currentStatus">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!formData.currentStatus" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="ecog">{{ $t("currentEcogScore") }}*</label>
            <div class="col-md-9">
              <v-select id="ecog" required :options="optionsEcog" label="display" :placeholder="$t('pleaseSelect')" v-model="formData.ecog">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!formData.ecog" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="secondary-diagnosis">{{ $tc("relevantSecondaryDiagnoses") }}</label>
            <div class="col-md-9">
              <textarea id="secondary-diagnosis" class="form-control" rows="4" v-model="formData.additionalDiagnosis"></textarea>
            </div>
          </div>
          <div class="text-right mt-4">
            <button class="btn btn-secondary" type="button" @click="onCancelDiagnosis">{{ $t("cancel") }}</button>
            <button class="btn btn-primary ml-2" type="button" @click="saveDiagnosis">{{ $t("admin.save") }}</button>
          </div>
        </div>
        <hr />
        <h5 id="anchor-diagnostics">{{ $t("performedDiagnostics") }}</h5>
        <div>
          <div class="form-row" v-for="biomarker in biomarkerList" :key="biomarker.code">
            <label class="col-md-3 col-form-label">{{ biomarker.display }}</label>
            <label class="col-md-9 col-form-label">{{ getBiomarkerValueForCode(biomarker.code) }}</label>
          </div>
          <div class="text-right">
            <button type="button" @click="editBiomarker = true" disabled class="btn btn-secondary">{{ $t("editDiagnostics") }}</button>
          </div>
        </div>
        <hr />
        <h5 id="anchor-previous-therapies">{{ $tc("courseOfDisease") }}</h5>
        <table class="table table-hover table-sm table-half-bordered" style="table-layout: fixed">
          <colgroup>
            <col style="width: 80px" />
            <col style="width: 80px" />
            <col style="width: 32%" />
            <col style="width: 68%" />
            <col style="width: 32px" />
          </colgroup>
          <thead>
            <tr class="row-header">
              <th>{{ $t("therapyStartDate") }}</th>
              <th>{{ $t("therapyEndDate") }}</th>
              <th>{{ $t("therapyType") }}/{{ $t("worklist.status") }}</th>
              <th>{{ $t("detailedInformation") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!therapies || !therapies.length">
              <td colspan="5" class="text-center">{{ $t("noEntriesFound") }}</td>
            </tr>
            <tr v-for="(therapy, index) in therapies" :key="index">
              <td>{{ getTherapyStartDate(therapy) }}</td>
              <td>{{ getTherapyEndDate(therapy) }}</td>
              <td>{{ getTherapyType(therapy) }}</td>
              <td :style="{ 'font-weight': getTherapyType(therapy) === 'Status' ? '500' : '' }">{{ getTherapyMedication(therapy) }}</td>
              <td>
                <span class="btn-link delete-icon" @click="onDeleteTherapy(therapy)"><delete-icon title="" /></span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="loadingNewTherapies">
          <spinner class="spinner mt-2" line-fg-color="#148898" line-bg-color="#99bfbf" size="large" :speed="1.5" />
        </div>
        <div v-if="!loadingNewTherapies">
          <form ref="addTherapiesForm">
            <table class="table table-bordered" style="table-layout: fixed" v-if="newTherapies != null && newTherapies.length > 0">
              <colgroup>
                <col style="width: 140px" />
                <col style="width: 140px" />
                <col style="width: 32%" />
                <col style="width: 68%" />
                <col style="width: 50px" />
              </colgroup>
              <thead>
                <tr class="row-header">
                  <th>{{ $t("therapyStartDate") }}*</th>
                  <th>{{ $t("therapyEndDate") }}</th>
                  <th>{{ $t("therapyType") }}/{{ $t("worklist.status") }}*</th>
                  <th>{{ $t("detailedInformation") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!newTherapies || !newTherapies.length">
                  <td colspan="5" class="text-center">{{ $t("noEntriesFound") }}</td>
                </tr>
                <tr v-for="(therapy, index) in newTherapies" :key="index">
                  <td><input type="text" required class="form-control" placeholder="MM/JJJJ" pattern="^((0[1-9]|[12][0-9]|3[01])\/)?(1[0-2]|0[1-9])\/\d{4}$" v-model="therapy.startDate" /></td>
                  <!-- TODO fix pattern so Date can include dd or not -->
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="MM/JJJJ"
                      pattern="^((0[1-9]|[12][0-9]|3[01])\/)?(1[0-2]|0[1-9])\/\d{4}$"
                      v-model="therapy.endDate"
                      v-if="therapy.therapyOption === 'Systemische Therapie' || therapy.therapyOption === 'Bestrahlung'"
                    />
                  </td>
                  <td>
                    <v-select v-model="therapy.therapyOption" :options="optionsTherapy" :placeholder="$t('pleaseSelect')">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!therapy.therapyOption" v-bind="attributes" v-on="events" />
                      </template>
                    </v-select>
                  </td>
                  <td>
                    <concept-select
                      v-if="therapy.therapyOption === 'Systemische Therapie'"
                      :fhirBaseUrl="fhirBaseUrl"
                      resourceName="ValueSet"
                      url="http://molit.eu/fhir/ValueSet/substances-protocols"
                      sort
                      :searchInputPlaceholder="$t('search')"
                      :token="token"
                      @error="$emit('error')"
                      v-model="therapy.medication"
                      multiple
                      required
                      mapToConceptMap
                      conceptMapUrl="http://molit.eu/fhir/vitu/ConceptMap/protocols-to-atc"
                      :sortFunction="medicationSortFunction"
                      class="multiple"
                    ></concept-select>
                    <v-select v-else-if="therapy.therapyOption === 'Status'" required :options="instantOfTimeList" label="display" :placeholder="$t('pleaseSelect')" v-model="therapy.status">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!therapy.status" v-bind="attributes" v-on="events" />
                      </template>
                    </v-select>
                    <input v-else-if="therapy.therapyOption === 'Operation'" type="text" class="form-control" :placeholder="$tc('comment') + ' - R0/R1'" v-model="therapy.note" />
                    <input v-else-if="therapy.therapyOption === 'Bestrahlung'" type="text" class="form-control" :placeholder="$tc('comment')" v-model="therapy.note" />
                    <span v-else class="form-control no-border">&mdash;</span>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link delete-icon" @click="onRemoveTherapy(index)"><delete-icon /></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div class="text-right">
            <button type="button" @click="onAddTherapy" class="btn btn-secondary">{{ $t("addEntry") }}</button>
          </div>
          <div class="text-right mt-4" v-if="newTherapies != null && newTherapies.length > 0">
            <button class="btn btn-secondary" type="button" @click="onCancelTherapies">{{ $t("cancel") }}</button>
            <button class="btn btn-primary ml-2" type="button" @click="onSaveTherapies">{{ $t("admin.save") }}</button>
          </div>
        </div>
        <hr />
        <h5 id="anchor-documents">{{ $tc("document", 2) }}</h5>
        <div v-if="loadingNewDocuments">
          <spinner class="spinner mt-2" line-fg-color="#148898" line-bg-color="#99bfbf" size="large" :speed="1.5" />
        </div>
        <div v-if="!loadingNewDocuments">
          <div v-for="document in documents" :key="document.id" class="d-flex justify-content-between">
            <a class="" @click="openDocument(document)" href="javascript:void(null);"><download-icon title="" /> {{ document.description }}</a>
            <span class="btn-link delete-icon" @click="onDeleteDocument(document)"><delete-icon title="" /></span>
          </div>
          <div v-if="!addDocuments">
            <div class="text-right">
              <button type="button" class="btn btn-secondary" @click="setAddDocuments(true)">{{ $t("addDocuments") }}</button>
            </div>
          </div>
          <div v-if="addDocuments">
            <div class="form-group form-row mt-4">
              <label class="col-md-3 col-form-label">{{ $t("mostRecentDischargeSummary") }}</label>
              <div class="col">
                <b-form-file v-model="newDocuments.dischargeSummary" :placeholder="$t('chooseFile')" accept="application/pdf" no-drop :browse-text="$t('browse')"></b-form-file>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label">{{ $tc("relevantPathologyReport") }}</label>
              <div class="col-md-9">
                <b-form-file v-model="newDocuments.report" :placeholder="$t('chooseFile')" accept="application/pdf" no-drop :browse-text="$t('browse')"></b-form-file>
              </div>
            </div>
            <div class="form-group form-row" v-for="(document, index) in newDocuments.additionalDocuments" :key="index">
              <label class="col-md-3 col-form-label">{{ $t("additionalDocument") }}</label>
              <div class="col">
                <b-form-file v-model="newDocuments.additionalDocuments[index]" :placeholder="$t('chooseFile')" accept="application/pdf" no-drop :browse-text="$t('browse')"></b-form-file>
              </div>
              <div class="col-md-auto">
                <button type="button" class="btn btn-link delete-icon" @click="onRemoveAdditionalDocuments(index)"><delete-icon /></button>
              </div>
            </div>
            <div class="text-right">
              <button type="button" @click="onAddAdditionalDocuments" class="btn btn-secondary">{{ $t("addAdditionalDocument") }}</button>
            </div>
            <div class="text-right mt-4">
              <button class="btn btn-secondary" type="button" @click="setAddDocuments(false)">{{ $t("cancel") }}</button>
              <button class="btn btn-primary ml-2" type="button" @click="saveDocuments">{{ $t("admin.save") }}</button>
            </div>
          </div>
        </div>
        <hr />
        <h5 id="anchor-further-information">{{ $tc("furtherInformation") }}</h5>
        <div v-if="!editInformation">
          <div v-if="comment && comment !== ''" v-html="comment"></div>
          <div v-else>-</div>
          <div class="text-right">
            <button type="button" @click="editInformation = true" class="btn btn-secondary">{{ $t("editFurtherInformation") }}</button>
          </div>
        </div>
        <div v-if="editInformation">
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label" for="comment">{{ $tc("comment") }}</label>
            <div class="col-md-9">
              <textarea id="comment" class="form-control" v-model="commentPlain" rows="4"></textarea>
            </div>
          </div>
          <div class="text-right mt-4">
            <button class="btn btn-secondary" type="button" @click="editInformation = false">{{ $t("cancel") }}</button>
            <button class="btn btn-primary ml-2" type="button" @click="saveComment">{{ $t("admin.save") }}</button>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "lodash";
import { Base64 } from "js-base64";
import { markdownToHtml, authenticatedDownload } from "@/util/util";
import { deleteResource, submitResource, updateResource } from "@molit/fhir-api";
import { deleteFile, deleteFromComposition, addDocuments, addTherapies } from "@/api/worklist-api";
import config from "@/config/config";
import { mapState } from "vuex";
import ConceptSelect from "@/components/ui/ConceptSelect";
import DeleteIcon from "vue-material-design-icons/Delete";
import DownloadIcon from "vue-material-design-icons/Download";
import Spinner from "vue-simple-spinner";
import DateInput from "@/components/ui/DateInput";
import { cloneDeep } from "lodash";

export default {
  props: {
    resources: {
      type: Array,
      default: () => {
        return [];
      }
    },

    task: {
      type: Object,
      default: () => {
        return {};
      }
    },

    biomarkerList: {
      type: Array,
      default: () => {
        return [];
      }
    },

    instantOfTimeList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      addDocuments: false,
      editDiagnosis: false,
      editIndication: false,
      editInformation: false,
      formData: {
        icd10: null,
        subType: null,
        dateOfDiagnosis: null,
        uicc: null,
        t: null,
        n: null,
        m: null,
        currentStatus: null,
        ecog: null,
        secondaryDiagnosis: null,
        consentForTumorboard: null,
        inclusionCriteriaResponse: null,
        comment: null,
        biomarkerList: null,
        consentTumorboard: null,
        consentScientificUsage: null,
        consentScientificQuestions: null,
        consentAdditionalReports: null,
        additionalDischargeSummaries: [],
        additionalReports: []
      },
      newDocuments: {
        additionalDocuments: [],
        dischargeSummary: null,
        report: null
      },
      loadingNewDocuments: false,
      loadingNewTherapies: false,
      loadingDiagnosis: false,
      newTherapies: [],
      optionsTherapy: ["Systemische Therapie", "Bestrahlung", "Operation", "Status"],
      newCommentPlain: null,
      medicationSortFunction: (c1, c2) => {
        if (!c1.selectTitle || !c2.selectTitle) {
          return 0;
        }
        if (c1.selectTitle.includes("(") && !c2.selectTitle.includes("(")) {
          return 1;
        }
        if (!c1.selectTitle.includes("(") && c2.selectTitle.includes("(")) {
          return -1;
        }
        return c1.selectTitle.localeCompare(c2.selectTitle);
      }
    };
  },

  computed: {
    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    ...mapState({
      token: state => state.authentication.keycloak.token
    }),

    patient() {
      if (!this.resources) {
        return null;
      }
      return this.resources.find(resource => resource.resourceType === "Patient");
    },

    patientFirstName() {
      return get(this.patient, "name[0].given[0]", "");
    },

    patientLastName() {
      return get(this.patient, "name[0].family", "");
    },

    episodeOfCare() {
      if (!this.resources) {
        return null;
      }
      return this.resources.find(resource => resource.resourceType === "EpisodeOfCare");
    },

    condition() {
      if (!this.resources) {
        return null;
      }
      return this.resources.find(resource => resource.resourceType === "Condition");
    },

    conditionDate() {
      if (!this.condition || !this.condition.onsetDateTime) {
        return null;
      }
      return this.$d(new Date(this.condition.onsetDateTime));
    },

    observations() {
      if (!this.resources) {
        return [];
      }
      return this.resources.filter(resource => resource.resourceType === "Observation");
    },

    therapies() {
      if (!this.resources) {
        return [];
      }
      return this.resources
        .filter(
          resource =>
            resource.resourceType === "MedicationStatement" ||
            resource.resourceType === "Procedure" ||
            (resource.resourceType === "Observation" && resource.code && resource.code.coding && resource.code.coding[0] && resource.code.coding[0].code === "C17747")
        )
        .sort((t1, t2) => {
          if (!t1 || !t2) {
            return 0;
          }
          const t1Date = this.getTherapyStartDate(t1);
          const t2Date = this.getTherapyStartDate(t2);
          if (!t1Date || !t2Date) {
            return 0;
          }

          let compare = t1Date.localeCompare(t2Date);
          if (compare === 0) {
            if (t1.resourceType !== "Observation" && t2.resourceType === "Observation") {
              compare = 1;
            } else if (t1.resourceType === "Observation" && t2.resourceType !== "Observation") {
              compare = -1;
            } else {
              compare = 0;
            }
          }
          return compare;
        });
    },

    composition() {
      if (!this.resources) {
        return null;
      }
      const compositions = this.resources.filter(resource => resource.resourceType === "Composition" && resource.title === "MTB Anmeldeformular");
      if (compositions != null && compositions.length === 1) {
        return compositions[0];
      }
      return null;
    },

    currentStatus() {
      return this.getObservationValueBySystemAndCode("http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl", "C25688");
    },

    ecogScore() {
      return this.getObservationValueBySystemAndCode("http://loinc.org", "89247-1");
    },

    tnmObservation() {
      return this.getObservationBySystemAndCode("http://loinc.org", "75620-5");
    },

    uicc() {
      return this.getObservationValueBySystemAndCode("http://loinc.org", "75620-5");
    },

    tValue() {
      if (!this.tnmObservation) {
        return "";
      }
      return this.getComponentValueByLoincCode(this.tnmObservation.component, "21905-5");
    },

    nValue() {
      if (!this.tnmObservation) {
        return "";
      }
      return this.getComponentValueByLoincCode(this.tnmObservation.component, "21906-3");
    },

    mValue() {
      if (!this.tnmObservation) {
        return "";
      }
      return this.getComponentValueByLoincCode(this.tnmObservation.component, "21907-1");
    },

    icd10() {
      if (!this.formData.icd10 || !this.formData.icd10.code || !this.formData.icd10.display) {
        return "";
      }
      return this.formData.icd10.code + " - " + this.formData.icd10.display;
    },

    icdo3() {
      return this.getObservationValueBySystemAndCode("http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl", "C25696");
    },

    indication() {
      if (!this.resources) {
        return null;
      }
      return this.resources.find(resource => resource.resourceType === "QuestionnaireResponse");
    },

    indicationItems() {
      return get(this.indication, "item", []);
    },

    trueIndicationItems() {
      return this.indicationItems.filter(i => get(i, "answer[0].valueBoolean", false));
    },

    documents() {
      if (!this.resources) {
        return [];
      }
      return this.resources.filter(resource => resource.resourceType === "DocumentReference" && resource.description);
    },

    commentResource() {
      if (!this.resources) {
        return "-";
      }
      return this.resources.find(resource => resource.resourceType === "DocumentReference" && get(resource, "type.coding", []).find(coding => coding.code === "C42619"));
    },

    comment() {
      if (!this.resources) {
        return "-";
      }
      const resource = this.resources.find(resource => resource.resourceType === "DocumentReference" && get(resource, "type.coding", []).find(coding => coding.code === "C42619"));
      const data = get(resource, "content[0].attachment.data", "");
      if (data) {
        return markdownToHtml(Base64.decode(data));
      }
      return "-";
    },

    commentPlain: {
      get() {
        if (!this.resources) {
          return "-";
        }
        const resource = this.resources.find(resource => resource.resourceType === "DocumentReference" && get(resource, "type.coding", []).find(coding => coding.code === "C42619"));
        const data = get(resource, "content[0].attachment.data", "");
        if (data) {
          return Base64.decode(data);
        }
        return "-";
      },

      set(value) {
        this.newCommentPlain = value;
      }
    },

    showCaseRegistrationButton() {
      if (this.indication == null) {
        return true;
      }
      return false;
    }
  },

  methods: {
    createFormData() {
      if (this.resources == null) {
        return;
      }
      const formData = {};
      this.setCondition(formData);
      this.formData = formData;
    },

    onCancelDiagnosis() {
      this.setCondition(this.formData);
      this.editDiagnosis = false;
    },

    setCondition(formData) {
      const condition = this.resources.find(resource => resource.resourceType === "Condition");
      if (condition != null) {
        formData.icd10 = get(condition, "code.coding[0]", {});
        if (formData.icd10.display == null) {
          formData.icd10.display = "";
        }
        formData.subType = this.getObservationValueBySystemAndCode("http://loinc.org", "59848-2");
        formData.dateOfDiagnosis = condition.onsetDateTime;
      }
    },

    getObservationBySystemAndCode(system, code) {
      if (!this.observations || !system || !code) {
        return null;
      }
      return this.observations.find(resource => get(resource, "code.coding[0].system") === system && get(resource, "code.coding[0].code") === code);
    },

    getObservationValueBySystemAndCode(system, code) {
      if (!this.observations || !system || !code) {
        return null;
      }
      return get(this.getObservationBySystemAndCode(system, code), "valueCodeableConcept.coding[0].display");
    },

    getBiomarkerForCode(code) {
      if (!this.observations || !code) {
        return null;
      }
      return this.observations.find(resource => get(resource, "code.coding[0].code") === code);
    },

    getBiomarkerValueForCode(code) {
      if (!this.observations || !code) {
        return null;
      }
      let value = get(this.getBiomarkerForCode(code), "valueCodeableConcept.coding[0].display");
      if (value) {
        if (value === "Yes") {
          value = "positive";
        } else if (value === "No") {
          value = "negative";
        }
        return this.$t(value.toLocaleLowerCase());
      }
      return null;
    },

    async onDeleteTherapy(therapy) {
      const answer = await this.$bvModal.msgBoxConfirm(this.$t("reallyDelete"), {
        title: this.$t("pleaseConfirm"),
        okTitle: this.$t("yes"),
        cancelTitle: this.$t("cancel")
      });

      if (answer) {
        try {
          const reference = therapy.resourceType + "/" + therapy.id;
          await deleteFromComposition(this.fhirBaseUrl, this.token, reference);
          await deleteResource(this.fhirBaseUrl, therapy, this.token);
          this.$emit("reload");
        } catch (e) {
          this.$emit("error", e);
        }
      }
    },

    async onDeleteDocument(document) {
      const answer = await this.$bvModal.msgBoxConfirm(this.$t("reallyDelete"), {
        title: this.$t("pleaseConfirm"),
        okTitle: this.$t("yes"),
        cancelTitle: this.$t("cancel")
      });

      if (answer) {
        try {
          const reference = document.resourceType + "/" + document.id;
          await deleteFromComposition(this.fhirBaseUrl, this.token, reference);
          await deleteFile(this.fhirBaseUrl, this.token, document);
          this.$emit("reload");
        } catch (e) {
          this.$emit("error", e);
        }
      }
    },

    getTherapyType(therapy) {
      if (!therapy) {
        return "";
      }

      if (therapy.resourceType === "Procedure") {
        const display = get(therapy, "category.coding[0].display");
        if (display === "Radiation Therapy") {
          return "Bestrahlung";
        } else if (display === "Surgery procedure") {
          return "Operation";
        }
        return get(therapy, "category.coding[0].display");
      } else if (therapy.resourceType === "Observation") {
        return "Status";
      } else {
        return "Systemische Therapie";
      }
    },

    getTherapyEndDate(therapy) {
      if (!therapy) {
        return "";
      }

      if (therapy.resourceType === "Procedure") {
        return get(therapy, "performedPeriod.end", "-");
      } else {
        return get(therapy, "effectivePeriod.end", "-");
      }
    },

    openDocument(documentReference) {
      const data = get(documentReference, "content[0].attachment.data");
      const location = get(documentReference, "content[0].attachment.url");
      if (data) {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = documentReference.description + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else if (location != null) {
        const title = get(documentReference, "content[0].attachment.title");
        authenticatedDownload(location, title, this.token);
      }
    },

    getComponentValueByLoincCode(components, code) {
      if (!components) {
        return "";
      }
      const component = components.find(c => get(c, "code.coding[0].code") === code);
      return get(component, "valueCodeableConcept.coding[0].display", "");
    },

    getTherapyStartDate(therapy) {
      if (!therapy) {
        return "";
      }

      if (therapy.resourceType === "Procedure") {
        return get(therapy, "performedPeriod.start", "-");
      } else if (therapy.resourceType === "Observation") {
        return get(therapy, "effectiveDateTime", "-");
      } else {
        return get(therapy, "effectivePeriod.start", "-");
      }
    },

    getTherapyMedication(therapy) {
      if (!therapy) {
        return "";
      }

      if (therapy.resourceType === "Procedure") {
        return get(therapy, "note[0].text", "-");
      } else if (therapy.resourceType === "Observation") {
        return get(therapy, "valueCodeableConcept.coding[0].display");
      } else {
        return get(therapy, "medicationCodeableConcept.coding[0].display");
      }
    },

    getAnswerFromItem(item) {
      const answer = get(item, "answer[0].valueBoolean", false);
      if (answer) {
        return this.$t("yes");
      } else {
        return this.$t("no");
      }
    },

    setAddDocuments(addDocuments) {
      this.addDocuments = addDocuments;
    },

    onAddAdditionalDocuments() {
      this.newDocuments.additionalDocuments.push(null);
    },

    onRemoveAdditionalDocuments(index) {
      this.newDocuments.additionalDocuments.splice(index, 1);
    },

    onAddTherapy() {
      this.newTherapies.push({});
    },

    onRemoveTherapy(index) {
      this.newTherapies.splice(index, 1);
    },

    async saveDiagnosis() {
      if (this.condition != null && this.formData.icd10 != null) {
        try {
          this.loadingDiagnosis = true;
          const newCondition = cloneDeep(this.condition);
          newCondition.code.coding[0].code = this.formData.icd10.code;
          newCondition.code.coding[0].display = this.formData.icd10.display;
          newCondition.onsetDateTime = this.formData.dateOfDiagnosis;
          await updateResource(this.fhirBaseUrl, newCondition, this.token);
          this.loadingDiagnosis = false;
          this.editDiagnosis = false;
          this.$emit("reload");
        } catch (e) {
          this.$emit("error", e);
          this.loadingDiagnosis = false;
        }
      }
    },

    async saveComment() {
      if (this.newCommentPlain != null) {
        const newComment = cloneDeep(this.commentResource);
        newComment.content[0].attachment.data = Base64.encode(this.newCommentPlain);
        updateResource(this.fhirBaseUrl, newComment, this.token);
      }
      this.$emit("reload");
    },

    async saveDocuments() {
      try {
        this.loadingNewDocuments = true;
        const transaction = {
          resourceType: "Bundle",
          type: "transaction",
          entry: []
        };
        const composition = {
          section: []
        };
        await addDocuments(this.fhirBaseUrl, this.token, composition, transaction, this.patient, this.newDocuments.dischargeSummary, this.newDocuments.report, this.newDocuments.additionalDocuments);
        await submitResource(this.fhirBaseUrl, transaction, this.token);
        // for (let i = 0; i < composition.section[0].entry.length; i++) {
        //   composition.section[0].entry[i].reference = response.data.entry[i].response.location;
        // }
        // if (this.composition != null) {
        //   const section = this.composition.section.find(section => section.title === "Arztbrief & Befunde");
        //   if (section != null) {
        //     console.log(section);
        //     console.log(composition.section[0].entry);
        //     section.entry = [...section.entry, ...composition.section[0].entry];
        //   }
        // }
        // await updateResource(this.fhirBaseUrl, this.condition, this.token);
        // console.log(this.composition);
        // console.log(composition);
        this.loadingNewDocuments = false;
        this.$emit("reload");
      } catch (e) {
        this.$emit("error", e);
        this.loadingNewDocuments = false;
      }
    },

    onCancelTherapies() {
      this.newTherapies = [];
    },

    async onSaveTherapies() {
      if (!this.$refs.addTherapiesForm.checkValidity()) {
        this.$refs.addTherapiesForm.reportValidity();
        return;
      }

      try {
        this.newTherapies = this.newTherapies.filter(t => t.therapyOption);
        this.loadingNewTherapies = true;
        const transaction = {
          resourceType: "Bundle",
          type: "transaction",
          entry: []
        };
        const composition = {
          section: []
        };
        addTherapies(composition, transaction, this.newTherapies, this.patient, this.episodeOfCare.id, this.condition.id);
        await submitResource(this.fhirBaseUrl, transaction, this.token);
        this.loadingNewTherapies = false;
        this.$emit("reload");
      } catch (e) {
        this.$emit("error", e);
        this.loadingNewTherapies = false;
      }
    }
  },

  mounted() {
    this.createFormData();
  },

  components: {
    ConceptSelect,
    DateInput,
    DeleteIcon,
    DownloadIcon,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.col-form-label {
  padding: 0.2rem 5px;
}

.form-row:hover {
  background: lighten($vitu-background, 3.5%);
}

.delete-icon {
  font-size: 1.2rem !important;
  color: $vitu-grey;
}

.btn-link {
  cursor: pointer;
}

h5 {
  background: $vitu-background;
  padding: 0.2rem 0.4rem;
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
}

hr {
  border: 0 !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.form-row {
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.2rem;

  label:first-child {
    padding-right: 1rem;
  }

  label:nth-child(2) {
    font-weight: 500;
  }
}

@media (min-width: 768px) {
  .form-row label:first-child {
    text-align: end;
  }
}
</style>
