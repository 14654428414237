<template>
  <div>
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
    />
    <div class="container">
      <breadcrumps v-if="editMode" :breadcrumps="[{ name: $tc('worklist.patient', 2), route: { name: 'patients' } }, { name: $t('worklist.editPatient') }]" />
      <breadcrumps v-else :breadcrumps="[{ name: $tc('worklist.patient', 2), route: { name: 'patients' } }, { name: $t('addPatient') }]" />
      <div class="page-header" v-if="editMode">
        <h5>{{ $t("worklist.editPatient") }}</h5>
      </div>
      <div class="page-header" v-else>
        <h5>{{ $t("addPatient") }}</h5>
      </div>
      <div class="page-body">
        <spinner v-if="spinner.loading" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :message="spinner.message" :speed="1.5" />
        <form autocomplete="off" ref="form" v-on:submit.prevent="onSubmit" v-if="!spinner.loading">
          <fieldset>
            <legend>{{ $t("masterData") }}</legend>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="patientId">{{ $t("patientId") }}</label>
              <div class="col-md-9">
                <input id="patientId" type="text" class="form-control" :placeholder="$t('patientId')" v-model="patientId" v-on:keyup.enter="onSubmit" />
                <small class="form-text text-muted">{{ $t("patientIdInfo") }}</small>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="organization">{{ $t("organization") }}*</label>
              <div class="col-md-9">
                <resource-select
                  required
                  :fhirBaseUrl="fhirBaseUrl"
                  :token="token"
                  resourceName="Organization"
                  :titleAttribute="{ value: 'name' }"
                  v-model="organization"
                  :searchAttributes="organizationSearchAttributes"
                  :searchInputPlaceholder="$t('search')"
                  :queryParams="queryParams"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="firstName">{{ $t("firstName") }}*</label>
              <div class="col-md-9">
                <input required id="firstName" type="text" class="form-control" :placeholder="$t('firstName')" v-model="firstName" @invalid="isEmpty" @input="onChange" v-on:keyup.enter="onSubmit" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="lastName">{{ $t("lastName") }}*</label>
              <div class="col-md-9">
                <input required id="lastName" type="text" class="form-control" :placeholder="$t('lastName')" v-model="lastName" @invalid="isEmpty" oninput="this.setCustomValidity('')" v-on:keyup.enter="onSubmit" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="birthDate">{{ $t("worklist.birthDate") }}*</label>
              <div class="col-md-9">
                <input
                  required
                  id="birthDate"
                  type="date"
                  class="form-control"
                  :max="today"
                  :placeholder="$t('worklist.birthDate')"
                  @invalid="invalidBirth"
                  oninput="this.setCustomValidity('')"
                  v-model="birthDate"
                  v-on:keyup.enter="onSubmit"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="gender">{{ $t("sex") }}*</label>
              <div class="col-md-9">
                <select required id="gender" type="date" class="form-control" :placeholder="$t('sex')" v-model="gender" @invalid="invalidGender" onchange="this.setCustomValidity('')" v-on:keyup.enter="onSubmit">
                  <option selected disabled :value="null">{{ $t("pleaseSelect") }}</option>
                  <option v-for="gender in genders" :value="gender.code" :key="gender.code">{{ $t(gender.code) }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="caseId">{{ $t("worklist.caseId") }}</label>
              <div class="col-md-9">
                <input id="caseId" type="text" class="form-control" :placeholder="$t('worklist.caseId')" v-model="caseId" v-on:keyup.enter="onSubmit" />
                <small class="form-text text-muted">{{ $t("caseIdInfo") }}</small>
              </div>
            </div>
            <!-- Address -->
            <hr />
          </fieldset>
          <fieldset>
            <legend>{{ $t("patientAddress") }}</legend>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="street">{{ $t("street") }}</label>
              <div class="col-md-9">
                <input id="street" type="text" class="form-control" v-model="address.street" :placeholder="$t('street')" v-on:keyup.enter="onSubmit" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="houseNumber">{{ $t("houseNumber") }}</label>
              <div class="col-md-9">
                <input id="houseNumber" type="text" class="form-control" v-model="address.houseNumber" :placeholder="$t('houseNumber')" v-on:keyup.enter="onSubmit" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="postalCode">{{ $t("postalCode") }}</label>
              <div class="col-md-9">
                <input
                  id="postalCode"
                  type="text"
                  class="form-control"
                  v-model="address.postalCode"
                  pattern="[0-9]{5}"
                  :placeholder="$t('postalCode')"
                  @invalid="invalidPostal"
                  @change="onChange"
                  oninput="this.setCustomValidity(' ')"
                  v-on:keyup.enter="onSubmit"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="city">{{ $t("city") }}</label>
              <div class="col-md-9">
                <input id="city" type="text" class="form-control" v-model="address.city" :placeholder="$t('city')" v-on:keyup.enter="onSubmit" />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="page-footer" v-if="!spinner.loading">
        <div class="spacer"></div>
        <button class="btn btn-secondary btn-cancel mr-2" @click="onCancel">{{ $t("cancel") }}</button>
        <button class="btn btn-primary" @click="onSubmit">{{ $t("admin.save") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumps from "@/components/ui/Breadcrumps";
import NotificationPanels from "@/components/ui/NotificationPanels";
import notifications from "@/mixins/notifications";
import { addPatient, updatePatient, getPatient } from "@/api/worklist-api";
import config from "@/config/config";
import { mapState } from "vuex";
import Spinner from "vue-simple-spinner";
import * as fhirApi from "@molit/fhir-api";
import { get } from "lodash";
import ResourceSelect from "@/components/ui/ResourceSelect";

export default {
  mixins: [notifications],

  data() {
    return {
      patient: {},
      episodeOfCare: {},
      spinner: { loading: false, message: "" },
      error: null,
      patientId: null,
      firstName: null,
      lastName: null,
      birthDate: null,
      gender: null,
      caseId: null,
      genders: null,
      organization: null,
      // TODO get users organization system
      patientIdentifierSystem: "molit/testsystem",
      organizationSearchAttributes: [
        {
          name: "Name",
          value: "name:contains"
        }
      ],
      address: {
        street: null,
        houseNumber: null,
        postalCode: null,
        city: null
      },
      queryParams: {
        _sort: "name",
        active: true
      }
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token,
      roles: state => state.authentication.keycloak.realmAccess.roles
    }),

    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    editMode() {
      return this.$route.params.id && this.$route.params.id !== "new";
    },

    today() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; //January is 0!
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },

  methods: {
    /**
     * Returns the Identifierobject
     */
    getIdentifier() {
      if (this.patient && this.patient.identifier && this.patient.identifier.length !== 0) {
        for (let i = 0; i < this.patient.identifier.length; i++) {
          if (this.patient.identifier[i].system === this.patientIdentifierSystem) {
            return this.patient.identifier[i].value;
          }
        }
      }
      return null;
    },

    /**
     * If patient is available, it checks if a identifier exists with the correct system and overwrite the value, else push a new element. The
     * identifier system depends on the organisation the user is part of.
     *
     * @param {String} value - patient identifier value
     */
    setIdentifier(value) {
      let identExists = false;
      if (this.patient && this.patient.identifier && this.patient.identifier.length !== 0) {
        for (let i = 0; i < this.patient.identifier.length; i++) {
          if (this.patient.identifier[i].system === this.patientIdentifierSystem) {
            this.patient.identifier[i].value = value;
            identExists = true;
          }
        }
      }
      if (!identExists) {
        if (!this.patient.identifier) {
          this.patient.identifier = [];
        }
        this.patient.identifier.push({ system: this.patientIdentifierSystem, value: value });
      }
    },

    async getAdministrativeGender() {
      const response = (await fhirApi.fetchByUrl(this.fhirBaseUrl + "/ValueSet/$expand?url=http://hl7.org/fhir/ValueSet/administrative-gender", {}, this.token)).data;
      this.genders = get(response, "expansion.contains");
    },

    async getEpisodeOfCare() {
      let url = this.fhirBaseUrl + "/EpisodeOfCare?patient=Patient/" + this.patient.id;
      let response = await fhirApi.fetchByUrl(url, null, this.token);

      this.episodeOfCare = get(response, "data.entry[0].resource");
      this.caseId = get(this.episodeOfCare, "identifier[0].value");
    },

    async getPatient() {
      let response = await getPatient(this.fhirBaseUrl, this.$route.params.id, {}, this.token);
      this.patient = response.data;
      this.patientId = this.getIdentifier();
      this.birthDate = this.patient.birthDate;
      this.gender = this.patient.gender;
      let hasNumber = /\d/;
      if (this.patient.address != null) {
        if (this.patient.address[0] != null && this.patient.address[0].line != null) {
          if (this.patient.address[0].line.length === 2) {
            this.address.street = this.patient.address[0].line[0];
            this.address.houseNumber = this.patient.address[0].line[1];
          }
          if (!hasNumber.test(this.patient.address[0].line[0])) {
            this.address.street = this.patient.address[0].line[0];
          }
          if (hasNumber.test(this.patient.address[0].line[0])) {
            this.address.houseNumber = this.patient.address[0].line[0];
          }
        }
        if (this.patient.address[0].postalCode != null) {
          this.address.postalCode = this.patient.address[0].postalCode;
        }
        if (this.patient.address[0].city != null) {
          this.address.city = this.patient.address[0].city;
        }
      }
      if (this.patient.name && this.patient.name[0] && this.patient.name[0].given) {
        this.firstName = this.patient.name[0].given[0];
        this.lastName = this.patient.name[0].family;
      }
      if (this.patient.managingOrganization && this.patient.managingOrganization.reference) {
        this.organization = {
          id: this.patient.managingOrganization.reference.split("/")[1],
          name: this.patient.managingOrganization.display
        };
      }
    },

    async getValueSets() {
      try {
        await this.getAdministrativeGender();
      } catch (e) {
        this.handleError(e, true);
      }
    },

    async getResources() {
      try {
        await this.getPatient();
        await this.getEpisodeOfCare();
      } catch (e) {
        this.spinner.loading = false;
        this.handleError(e, true);
      }
    },

    async createResources() {
      let identifier = { system: this.patientIdentifierSystem, value: this.patientId };
      let address = {
        line: [this.address.street, this.address.houseNumber],
        city: this.address.city,
        postalCode: this.address.postalCode
      };
      const response = await addPatient(this.fhirBaseUrl, this.token, identifier, this.firstName, this.lastName, this.birthDate, this.gender, this.caseId, this.organization, address);
      const patientLocation = get(response, "data.entry[1].response.location");
      let patientId;

      if (patientLocation) {
        patientId = patientLocation.split("/")[1];
      }

      if (this.$route.query.redirect && patientId) {
        this.$router.push({ name: "patient", params: { id: patientId }, query: { success: "patientCreatedSuccessfully" } });
      }
    },

    async createByQueryParams() {
      this.spinner.message = this.$t("worklist.loadingData");

      this.patient = {
        resourceType: "Patient",
        active: true
      };

      let newName = {
        use: "official"
      };
      // this.spinner.message = this.$t("worklist.checkIfPatientExists");
      // if (this.$route.query.patId) {
      //   try {
      //     const patient = fhirApi.mapFhirResponse(await fhirApi.fetchByUrl(encodeURI(this.fhirBaseUrl + `/Patient?identifier=` + this.patientIdentifierSystem + `|${this.$route.query.patId}`), {}))[0];
      //     if (patient) {
      //       this.$router.push({ name: "patient", params: { id: patient.id } });
      //       return;
      //     } else {
      //       this.patient.identifier = [{ system: this.patientIdentifierSystem, value: this.$route.query.patId }];
      //     }
      //   } catch (e) {
      //     //
      //   }
      //   this.patientId = this.$route.query.patId;
      // }
      this.spinner.message = this.$t("worklist.creatingPatient");

      if (this.$route.query.patId) {
        this.patientId = this.$route.query.patId;
      }

      if (this.$route.query.firstName) {
        newName.given = [this.$route.query.firstName];
      }

      if (this.$route.query.lastName) {
        newName.family = this.$route.query.lastName;
      }
      this.patient.name = [newName];
      if (this.patient.name && this.patient.name[0] && this.patient.name[0].given) {
        this.firstName = this.patient.name[0].given[0];
        this.lastName = this.patient.name[0].family;
      }

      if (this.$route.query.birthDate) {
        this.patient.birthDate = [this.$route.query.birthDate];
        this.birthDate = this.patient.birthDate;
      }

      if (this.$route.query.gender) {
        switch (this.$route.query.gender) {
          case "M":
          case "m":
            this.patient.gender = "male";
            break;
          case "W":
          case "w":
          case "F":
          case "f":
            this.patient.gender = "female";
            break;
          case "U":
          case "u":
          case "X":
          case "x":
          case "S":
          case "s":
          case "D":
          case "d":
          case "O":
          case "o":
            this.patient.gender = "other";
            break;
          // this.patient.gender = "unknown";
          // break;
        }
        this.gender = this.patient.gender;
      }
      if (this.$route.query.caseId) {
        this.caseId = this.$route.query.caseId;
      }

      if (this.$route.query.street) {
        this.address.street = this.$route.query.street;
      }
      if (this.$route.query.houseNumber) {
        this.address.houseNumber = this.$route.query.houseNumber;
      }
      if (this.$route.query.postalCode) {
        this.address.postalCode = this.$route.query.postalCode;
      }
      if (this.$route.query.city) {
        this.address.city = this.$route.query.city;
      }
      //TODO Select organisation automatically
    },

    async updateResources() {
      this.setIdentifier(this.patientId);
      let address = {
        line: [this.address.street, this.address.houseNumber],
        city: this.address.city,
        postalCode: this.address.postalCode
      };
      await updatePatient(this.fhirBaseUrl, this.token, this.patient, this.patient.id, this.patient.identifier, this.firstName, this.lastName, this.birthDate, this.gender, this.episodeOfCare, this.caseId, this.organization, address);
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === "clinical-case") {
          this.$router.push({ name: this.$route.query.redirect, params: { id: this.$route.query.taskId }, query: { success: "patientUpdatedSuccessfully" } });
        } else {
          this.$router.push({ name: this.$route.query.redirect, query: { patientId: this.patient.id, success: "patientUpdatedSuccessfully" } });
        }
      }
    },

    async onSubmit() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }
      this.spinner.loading = true;

      try {
        if (this.editMode) {
          await this.updateResources();
        } else {
          await this.createResources();
        }
      } catch (e) {
        this.handleError(e, true);
      }
      this.spinner.loading = false;
    },

    onCancel() {
      if (this.$route.query.redirect === "clinical-case") {
        this.$router.push({ name: this.$route.query.redirect, params: { id: this.$route.query.taskId } });
      } else {
        this.$router.push({ name: this.$route.query.redirect, query: { patientId: this.patient.id } });
      }
    },

    isEmpty(event) {
      event.target.setCustomValidity(this.$t("validationMsg.empty"));
    },
    invalidBirth(event) {
      event.target.setCustomValidity(this.$t("validationMsg.birthDate"));
    },
    invalidGender(event) {
      event.target.setCustomValidity(this.$t("validationMsg.gender"));
    },
    invalidPostal(event) {
      event.target.setCustomValidity(this.$t("validationMsg.postalCode"));
    },
    onChange(event) {
      event.target.setCustomValidity("");
    }
  },

  async mounted() {
    this.spinner.loading = true;
    await this.getValueSets();

    if (this.editMode) {
      await this.getResources();
    } else if (this.$route.query.patId) {
      await this.createByQueryParams();
    }

    this.spinner.loading = false;
  },

  components: {
    Spinner,
    NotificationPanels,
    Breadcrumps,
    ResourceSelect
  }
};
</script>

<style lang="scss" scoped>
legend {
  font-size: 1.2rem;
}
</style>
