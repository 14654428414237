<template>
  <div>
    <spinner class="spinner mt-2" v-if="loading" line-fg-color="#148898" line-bg-color="#99bfbf" size="large" :speed="1.5" />
    <div v-else>
      <div class="case-overview">
        <div class="menu">
          <div class="menu-header">
            <!-- <h5>{{ $t("caseOverview") }}</h5> -->
            <div v-if="patient">
              <h5>{{ patientLastName }}, {{ patientFirstName }}</h5>
            </div>
          </div>
          <ul class="nav flex-column">
            <li class="nav-item">
              <button :class="['btn btn-link nav-link', { 'font-weight-bold': tabIndex === 0 }]" @click="goToTab(0)">{{ $t("planner.patientInformation") }}</button>
              <ul v-if="tabIndex === 0" class="submenu">
                <li class="nav-item">
                  <a class="nav-link" href="#anchor-patient">{{ $tc("worklist.patient") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#anchor-inclusion-criteria">{{ $tc("inclusionCriteria") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#anchor-diagnosis">{{ $tc("worklist.diagnosis") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#anchor-diagnostics">{{ $tc("performedDiagnostics") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#anchor-previous-therapies">{{ $tc("courseOfDisease") }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#anchor-documents">{{ $tc("document", 2) }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#anchor-further-information">{{ $tc("furtherInformation") }}</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <button :class="['btn btn-link nav-link', { 'font-weight-bold': tabIndex === 1 }]" @click="goToTab(1)">{{ $t("molecularDiagnostic") }}</button>
            </li>
            <li class="nav-item">
              <button :class="['btn btn-link nav-link', { 'font-weight-bold': tabIndex === 2 }]" @click="goToTab(2)">
                {{ $t("planner.comments") }} <span class="badge badge-primary">{{ commentsTotal }}</span>
              </button>
            </li>
            <li class="nav-item" v-if="enableCds">
              <button :class="['btn btn-link nav-link', { 'font-weight-bold': tabIndex === 3 }]" @click="goToTab(3)">
                {{ $tc("study", 2) }} <span class="badge badge-primary">{{ studiesTotal }}</span>
              </button>
            </li>
            <li class="nav-item" v-if="isModerator || isFreigeber">
              <button :class="['btn btn-link nav-link', { 'font-weight-bold': (!enableCds && tabIndex === 3) || (enableCds && tabIndex === 4) }]" @click="goToTab(enableCds ? 4 : 3)">{{ $t("planner.therapyRecommendation") }}</button>
            </li>
            <li class="nav-item">
              <button :class="['btn btn-link nav-link', { 'font-weight-bold': tabIndex === statusTabIndex }]" @click="goToTab(statusTabIndex)">{{ $t("worklist.status") }}</button>
            </li>
          </ul>
        </div>
        <div class="main">
          <div class="container">
            <div class="page-header">
              <!-- <h5>{{ $t("caseOverview") }}</h5> -->
              <div class="row spacer" v-if="patient">
                <div class="col-md-4">
                  <strong>{{ $t("worklist.birthDate") }}:</strong> {{ $d(new Date(patient.birthDate)) }}
                </div>
                <div class="col-md-3">
                  <strong>{{ $t("fhir.gender") }}:</strong> {{ $t(patient.gender) }}
                </div>
                <div class="col-md-5">
                  <strong>{{ $t("worklist.diagnosis") }}:</strong> {{ conditionString }}
                </div>
              </div>
            </div>
            <div class="page-body">
              <b-tabs fill v-model="tabIndex">
                <b-tab>
                  <template slot="title">
                    {{ $t("planner.patientInformation") }}
                  </template>
                  <patient-information :resources="patientEverything" :task="task" :biomarkerList="biomarkerList" :instantOfTimeList="instantOfTimeList" @error="onError" @reload="onReload"></patient-information>
                </b-tab>
                <b-tab>
                  <template slot="title">{{ $t("molecularDiagnostic") }}</template>
                  <results
                    :reportIds="reportIds"
                    :episodeOfCareId="episodeOfCare ? episodeOfCare.id : ''"
                    :patientId="patientId"
                    :importantVariants="importantVariants"
                    :generatedCaseId="generatedCaseId"
                    :biomarkers="biomarkers"
                    @error="onError"
                    @reload="onReload"
                    @addBiomarker="addBiomarker"
                    @deleteBiomarker="deleteBiomarker"
                  />
                </b-tab>
                <b-tab>
                  <template slot="title">
                    {{ $t("planner.comments") }} <span class="badge badge-primary">{{ commentsTotal }}</span>
                  </template>
                  <comments @updateCommentsTotal="onUpdateCommentsTotal" @error="onError" :patientId="patient ? patient.id : ''" :episodeOfCareId="episodeOfCare ? episodeOfCare.id : ''"></comments>
                </b-tab>
                <b-tab v-if="enableCds">
                  <template slot="title">
                    {{ $tc("study", 0) }} <span class="badge badge-primary">{{ studiesTotal }}</span>
                  </template>
                  <cds :patient="patient" :condition="condition" :subtype="subtype" :uicc="uicc" :variants="variants" @error="onError" @updateStudiesTotal="onUpdateStudiesTotal" />
                </b-tab>
                <b-tab :title="$t('planner.therapyRecommendation')" v-if="isModerator || isFreigeber">
                  <therapy-recommendation
                    :episodeOfCare="episodeOfCare"
                    :instantOfTimeList="instantOfTimeList"
                    :evidenceLevelList="evidenceLevelList"
                    :recommendationCategoryList="recommendationCategoryList"
                    :recommendationList="recommendationList"
                    :variants="variants"
                    :patient="patient"
                    @reload="onReload"
                    v-if="instantOfTimeList && evidenceLevelList && recommendationCategoryList"
                    @exportProtocol="exportProtocol"
                  ></therapy-recommendation>
                </b-tab>
                <b-tab :title="$t('worklist.status')">
                  <case-options :task="task" :statuses="statuses" :reasonsForCancellation="reasonsForCancellation" @updateTask="updateTask"></case-options>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaseOptions from "./moderator/CaseOptions";
import Comments from "./moderator/Comments";
import PatientInformation from "./moderator/PatientInformation";
import Results from "./moderator/Results";
import Spinner from "vue-simple-spinner";
import TherapyRecommendation from "./moderator/TherapyRecommendation";
import { fetchResource, fetchByUrl, mapFhirResponse, deleteResource } from "@molit/fhir-api";
import { updateTask, exportProtocol, fetchCaseOverviewResources, addBiomarker } from "@/api/worklist-api";
import config from "@/config/config";
import { get } from "lodash";
import { mapState } from "vuex";
import roles from "@/model/roles";
import Cds from "@/components/moderator/Cds";
import fhirpath from "@/assets/js/fhirpath.min.js";

export default {
  props: {
    caseId: {
      type: [String, Number]
    },
    episodeOfCareId: {
      type: [String, Number]
    }
  },

  data() {
    return {
      biomarkerList: null,
      patientEverything: null,
      task: null,
      loading: true,
      statuses: null,
      reasonsForCancellation: null,
      tabIndex: 0,
      commentsTotal: 0,
      studiesTotal: 0,
      instantOfTimeList: null,
      evidenceLevelList: null,
      recommendationCategoryList: null
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token,
      keycloak: state => state.authentication.keycloak
    }),

    recommendationList() {
      if (!this.patientEverything) {
        return null;
      }
      return this.patientEverything.find(resource => resource.resourceType === "List" && get(resource, "code.coding[0].code") === "93341-6");
    },

    biomarkers() {
      if (!this.patientEverything) {
        return null;
      }
      return this.patientEverything.filter(resource => resource.resourceType === "Observation" && (get(resource, "code.coding[0].code") === "C120465" || get(resource, "code.coding[0].code") === "94076-7"));
    },

    reportIds() {
      if (this.patientEverything) {
        return this.patientEverything.filter(resource => resource.resourceType === "DiagnosticReport").map(resource => resource.id);
      }
      return null;
    },

    enableCds() {
      return config.ENABLE_CDS;
    },

    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    patient() {
      if (!this.patientEverything) {
        return null;
      }
      return this.patientEverything.find(resource => resource.resourceType === "Patient");
    },

    episodeOfCare() {
      if (!this.patientEverything) {
        return null;
      }
      return this.patientEverything.find(resource => resource.resourceType === "EpisodeOfCare");
    },

    generatedCaseId() {
      return fhirpath.evaluate(this.episodeOfCare, "identifier.where(system='http://molit.eu/fhir/vitu/sid/molit-episode-of-care').value")[0];
    },

    importantVariants() {
      if (!this.patientEverything) {
        return null;
      }
      return this.patientEverything.find(resource => resource.resourceType === "List" && get(resource, "code.coding[0].code") === "C115916");
    },

    variants() {
      if (!this.patientEverything) {
        return null;
      }
      return this.patientEverything.filter(resource => resource.resourceType === "Observation" && get(resource, "code.coding[0].system") === "http://loinc.org" && get(resource, "code.coding[0].code") === "69548-6");
    },

    patientId() {
      return get(this.patient, "id", null);
    },

    patientFirstName() {
      return get(this.patient, "name[0].given[0]", "");
    },

    patientLastName() {
      return get(this.patient, "name[0].family", "");
    },

    condition() {
      if (this.patientEverything != null) {
        return this.patientEverything.find(resource => resource.resourceType === "Condition");
      }
      return null;
    },

    conditionString() {
      if (!this.patientEverything) {
        return "-";
      }
      return get(this.condition, "code.coding[0].code", "") + " - " + get(this.condition, "code.coding[0].display", "").split("|")[0];
    },

    subtype() {
      if (this.patientEverything != null) {
        return this.getObservationBySystemAndCode("http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl", "C25696");
      }
      return null;
    },

    uicc() {
      if (this.patientEverything != null) {
        return this.getObservationBySystemAndCode("http://loinc.org", "75620-5");
      }
      return null;
    },

    isModerator() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.MODERATOR);
      } else {
        return false;
      }
    },

    isFreigeber() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.FREIGEBER);
      } else {
        return false;
      }
    },

    statusTabIndex() {
      if (this.isFreigeber || this.isModerator) {
        if (this.enableCds) {
          return 5;
        } else {
          return 4;
        }
      } else {
        if (this.enableCds) {
          return 4;
        } else {
          return 3;
        }
      }
    }
  },

  methods: {
    async fetchResources() {
      try {
        await Promise.all([this.fetchPatientInformation(), this.fetchValueSets()]);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$emit("error", e);
      }
    },

    async addBiomarker(type, date, value) {
      try {
        const response = await addBiomarker(this.fhirBaseUrl, type, date, value, this.patientId, this.episodeOfCare.id, this.token);
        this.patientEverything.push(response.data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$emit("error", e);
      }
    },

    async deleteBiomarker(observation) {
      try {
        await deleteResource(this.fhirBaseUrl, observation, this.token);
        this.patientEverything = this.patientEverything.filter(resource => resource.id !== observation.id);
      } catch (e) {
        this.loading = false;
        this.$emit("error", e);
      }
    },

    async fetchValueSets() {
      const resources = await fetchCaseOverviewResources(this.fhirBaseUrl, this.token);
      this.statuses = resources.statuses.sort((e1, e2) => {
        return e1.code.localeCompare(e2.code);
      });
      this.reasonsForCancellation = resources.reasonsForCancellation;
      this.biomarkerList = resources.biomarkerList;
      this.instantOfTimeList = resources.instantOfTimeList;
      this.recommendationCategoryList = resources.recommendationCategoryList;
      this.evidenceLevelList = resources.evidenceLevelList;
    },

    async fetchPatientInformation() {
      if (this.caseId) {
        this.task = (await fetchResource(this.fhirBaseUrl, "Task", this.caseId, {}, this.token)).data;

        if (this.task.for && this.task.for.reference) {
          const response = await fetchByUrl(`${this.fhirBaseUrl}/Patient/${this.task.for.reference.split("/")[1]}/$everything?_count=1000`, {}, this.token);
          this.patientEverything = mapFhirResponse(response);
        }
      }

      if (this.episodeOfCareId) {
        const episodeOfCare = (await fetchResource(this.fhirBaseUrl, "EpisodeOfCare", this.episodeOfCareId, {}, this.token)).data;

        const response = await fetchByUrl(`${this.fhirBaseUrl}/Patient/${episodeOfCare.patient.reference.split("/")[1]}/$everything?_count=1000`, {}, this.token);
        this.patientEverything = mapFhirResponse(response);
        this.task = this.patientEverything.find(resource => resource.resourceType === "Task");
      }
    },

    onError(e) {
      this.$emit("error", e);
    },

    async updateTask(task, status, reasonForCancellation, reasonForCancellationOther) {
      try {
        const tabIndex = this.tabIndex;
        this.loading = true;
        this.task = (await updateTask(this.fhirBaseUrl, this.token, task, status, reasonForCancellation, reasonForCancellationOther)).data;
        this.loading = false;
        this.tabIndex = tabIndex;
      } catch (e) {
        this.loading = false;
        this.$emit("error", e);
      }
    },

    async exportProtocol() {
      const modalId = "export-modal";
      try {
        this.$bvModal.msgBoxOk([this.$createElement("b-spinner")], {
          id: modalId,
          size: "sm",
          bodyClass: "text-center",
          footerClass: "no-display"
        });

        const bundle = (await fetchByUrl(`${this.fhirBaseUrl}/Patient/${this.patientId}/$everything?_count=1000`, {}, this.token)).data;
        const response = await exportProtocol(config.FHIR_SERVICES_URL + "/toRecommendationDoc", this.token, bundle);

        let filename;

        if (this.patient && this.patient.managingOrganization) {
          filename = new Date().toLocaleDateString("en-CA") + "_Empfehlungsprotokoll_MTB_ZvPM_" + this.patientLastName + "_" + this.patientFirstName + "_" + this.patient.managingOrganization.display + ".docx";
        } else {
          filename = new Date().toLocaleDateString("en-CA") + "_Empfehlungsprotokoll_MTB_ZvPM.docx";
        }

        this.$bvModal.hide(modalId);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.$bvModal.hide(modalId);
        this.$emit("error", e);
      }
    },

    goToTab(index) {
      this.tabIndex = index;
    },

    onUpdateCommentsTotal(commentsTotal) {
      this.commentsTotal = commentsTotal;
    },

    onUpdateStudiesTotal(studiesTotal) {
      this.studiesTotal = studiesTotal;
    },

    getObservationBySystemAndCode(system, code) {
      if (!this.patientEverything || !system || !code) {
        return null;
      }
      return this.patientEverything.find(resource => get(resource, "code.coding[0].system") === system && get(resource, "code.coding[0].code") === code);
    },

    async onReload() {
      this.loading = true;
      this.patientEverything = null;
      this.task = null;
      this.commentsTotal = 0;
      this.studiesTotal = 0;
      await this.fetchPatientInformation();
      this.loading = false;
    }
  },

  async created() {
    await this.fetchResources();
  },

  mounted() {
    if (this.$route.query.tab != null) {
      this.goToTab(parseInt(this.$route.query.tab));
    }
  },

  watch: {
    caseId() {
      this.onReload();
    },

    episodeOfCareId() {
      this.onReload();
    },

    tabIndex(index) {
      if (index != this.$route.query.tab) {
        this.$router.push({ path: this.$route.path, query: { ...this.$route.query, tab: index } });
      }
    },

    "$route.query.tab"(tab) {
      if (tab != null) {
        this.goToTab(parseInt(tab));
      }
    }
  },

  components: {
    CaseOptions,
    Cds,
    Comments,
    PatientInformation,
    Results,
    Spinner,
    TherapyRecommendation
  }
};
</script>

<style lang="scss" scoped>
.patient-information {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.case-overview {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu {
  min-width: 260px;
  width: 260px;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  position: fixed;
  top: $navbar-height;
  height: calc(100vh - #{$navbar-height});
  background: $vitu-background-light;

  .nav-link {
    padding: 0.2rem 1rem;
  }

  .submenu {
    list-style: none;
    padding-left: 1rem;
    padding-bottom: 0.15rem;

    .nav-link {
      padding: 0.05rem 1rem;
    }
  }

  .menu-header {
    padding: 1rem;
    display: flex;
    align-items: center;
    // height: 3.6rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0.5rem;

    h5 {
      margin-bottom: 0;
    }
  }
}

// .page-header {
// margin-top: 0.8rem;
// border: 0;
// padding: 0rem 0.4rem;
// background: $vitu-background;
// position: sticky;
// top: $navbar-height;
// z-index: 2;
// }

.main {
  margin-left: 260px;
  flex: 1;
}

.btn-link.nav-link {
  text-align: left;
}

// .bottom-sheet {
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background: white;
//   z-index: 1001;
//   height: 50vh;
//   box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
//   overflow: auto;
// }
</style>
