<template>
  <div>
    <b-card no-body class="tab-container" v-show="!collapseControlPanel" style="width: 285px">
      <b-tabs card class="tabs" v-model="tabIndex" justified>
        <b-tab active class="tab-pane" ref="tabParticipants">
          <template slot="title">
            <div id="participant-list"><account-multiple-icon class="tab-icon" title="" /></div>
            <b-tooltip target="participant-list" triggers="hover" :title="$t('participantList')" />
          </template>
          <div class="panel-wrapper">
            <div v-if="error" class="error-panel">{{ error }}</div>
            <participant-list :participants="participants" class="participant-list" @mute="sendAdminMute" />
          </div>
        </b-tab>
        <b-tab class="tab-pane" ref="tabChat" :title-link-class="{ update_trigger_hack: '' }">
          <template slot="title">
            <div id="chat">
              <forum-icon class="tab-icon" title="" />
              <sup v-if="newMessages > 0">
                <b-badge variant="danger">{{ newMessages }}</b-badge>
              </sup>
            </div>
            <b-tooltip target="chat" triggers="hover" :title="$t('chat.chat')" />
          </template>
          <div v-if="error" class="error-panel">{{ error }}</div>
          <chat v-else :messages="messages" :visible="tabIndex === 1" @message="onownmessage" @startTyping="sendStartTyping" @endTyping="sendStopTyping" :subject="subject" :typingText="typingText" />
        </b-tab>
        <b-tab class="tab-pane" v-if="showEntries" ref="tabCase">
          <template slot="title">
            <format-list-checkbox-icon id="case-list" class="tab-icon" />
            <b-tooltip target="case-list" triggers="hover" :title="$t('caseList')" />
          </template>
          <div class="panel-wrapper">
            <div class="moderator-panel" v-if="keycloak.hasRealmRole('vitu-moderator') && !deactivateDocumentation">
              <h6>{{ $t("openCaseOverview") }}</h6>
              <p>{{ $t("caseOverviewDescription") }}</p>
              <router-link class="btn btn-primary" target="_blank" :to="{ name: 'conference-moderator' }">{{ $t("openCaseOverview") }} <open-in-new-icon /></router-link>
            </div>
            <!-- <hr />
            <div class="cases-panel">
              <div v-for="(entry, index) in filteredEntries" :key="entry ? entry.id : index">{{ index + 1 }} {{ entry && entry.patient ? entry.patient.firstName : "-" }} {{ entry && entry.patient ? entry.patient.lastName : "-" }}</div>
              <div v-if="!filteredEntries.length">{{ $t("conference.noEntries") }}</div>
            </div> -->
          </div>
        </b-tab>
        <b-tab class="tab-pane" ref="tabSettings">
          <template slot="title">
            <div id="settings"><cog-icon class="tab-icon" /></div>
            <b-tooltip target="settings" triggers="hover" :title="$t('settings')" />
          </template>
          <div class="panel-wrapper">
            <div class="moderator-panel">
              <h6>{{ $t("microphone") }}</h6>
              <div class="form-check" v-for="device in audioDevices" :key="device.deviceId">
                <input class="form-check-input" type="radio" name="audio-device" :id="'audio-device-' + device.deviceId" :value="device.deviceId" v-model="audioDevice" />
                <label class="form-check-label" :for="'audio-device-' + device.deviceId">
                  {{ device.label }}
                </label>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div class="options-panel">
        <div id="network-strength" class="option-info" v-if="!participantOnly">
          <network-strength1-alert-icon class="network-strength-alert-icon" title="" v-if="currentRoundTripTime === 0 || currentRoundTripTime > 1" />
          <network-strength4-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime <= 0.02 && currentRoundTripTime > 0" />
          <network-strength3-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime <= 0.05 && currentRoundTripTime > 0.02" />
          <network-strength2-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime <= 0.2 && currentRoundTripTime > 0.05" />
          <network-strength1-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime > 0.2" />
        </div>
        <div class="vertical-rule" v-if="!participantOnly"></div>
        <button type="button" class="btn btn-link" @click="$emit('toggleAudio')" id="audio" v-if="!participantOnly">
          <microphone-icon title="" class="option-icon" v-if="audio" />
          <microphone-off-icon title="" class="option-icon icon-danger" v-if="!audio" />
        </button>
        <button type="button" class="btn btn-link" @click="$emit('toggleVideo')" id="video" v-if="!participantOnly">
          <video-icon title="" class="option-icon" v-if="video" />
          <video-off-icon title="" :class="['option-icon', { 'disabled-icon': !canAddVideo }, { 'icon-danger': canAddVideo }]" v-if="!video" />
        </button>
        <button type="button" class="btn btn-link" id="screen-share" v-if="!screenShared && !participantOnly" @click="onShareScreen">
          <monitor-icon class="tab-icon" />
        </button>
        <button type="button" class="btn btn-primary" id="screen-share" v-if="screenShared && !participantOnly" @click="onUnshareScreen">
          <monitor-icon class="tab-icon" />
        </button>
        <div v-if="participantOnly">
          <button type="button" class="btn btn-link" id="publish" @click="$emit('publish')">
            <phone-icon class="tab-icon" />
            {{ $t("dialIn") }}
          </button>
        </div>
        <div class="vertical-rule"></div>
        <button type="button" class="btn btn-link" @click="$emit('leave')" id="leave">
          <exit-run-icon title="" class="option-icon" />
        </button>
        <b-tooltip target="network-strength" triggers="hover" :title="currentRoundTripTime * 1000 + ' ms'" />
        <b-tooltip target="audio" triggers="hover" :title="audio ? $t('conference.muteAudio') : $t('conference.unmuteAudio')" />
        <b-tooltip target="video" triggers="hover" :title="tooltipVideo" />
        <b-tooltip target="screen-share" triggers="hover">
          <span v-if="!screenShared">
            {{ $t("conference.shareScreen") }}
          </span>
          <span v-else>
            {{ $t("conference.unshareScreen") }}
          </span>
        </b-tooltip>
        <b-tooltip target="leave" triggers="hover" :title="$t('conference.leaveConference')" />
      </div>
      <button type="button" class="btn btn-link collapse-panel" id="collapse-btn" @click="collapseControlPanelHandler()">
        <div class="collapse-panel-content">
          {{ $t("collapse") }}
          <chevron-right-icon />
        </div>
      </button>
    </b-card>
    <!-- Collapsed Control Panel -->
    <b-card no-body class="tab-container" v-if="collapseControlPanel" style="width: 60px; padding-top: 0.5rem; min-height: 470px">
      <button type="button" class="btn btn-link" @click="collapseControlPanelHandler()">
        <div id="participant-list-c"><account-multiple-icon class="tab-icon" title="" /></div>
      </button>
      <button type="button" class="btn btn-link" @click="collapseControlPanelHandler('chat')">
        <div id="chat-c">
          <forum-icon class="tab-icon" title="" />
          <sup v-if="newMessages > 0">
            <b-badge variant="danger">{{ newMessages }}</b-badge>
          </sup>
        </div>
      </button>
      <button type="button" class="btn btn-link" @click="collapseControlPanelHandler('case')">
        <format-list-checkbox-icon id="case-list-c" class="tab-icon" />
      </button>
      <button type="button" class="btn btn-link" @click="collapseControlPanelHandler('settings')">
        <div id="settings-c"><cog-icon class="tab-icon" /></div>
      </button>

      <div class="options-panel-collapsed">
        <div v-if="!participantOnly">
          <div class="horizontal-rule"></div>
          <div id="network-strength-c" class="option-info">
            <network-strength1-alert-icon class="network-strength-alert-icon" title="" v-if="currentRoundTripTime === 0 || currentRoundTripTime > 1" />
            <network-strength4-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime <= 0.02 && currentRoundTripTime > 0" />
            <network-strength3-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime <= 0.05 && currentRoundTripTime > 0.02" />
            <network-strength2-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime <= 0.2 && currentRoundTripTime > 0.05" />
            <network-strength1-icon class="network-strength-icon" title="" v-else-if="currentRoundTripTime > 0.2" />
          </div>
          <div class="horizontal-rule"></div>
          <button type="button" class="btn btn-link" @click="$emit('toggleAudio')" id="audio-c">
            <microphone-icon title="" class="option-icon" v-if="audio" />
            <microphone-off-icon title="" class="option-icon icon-danger" v-if="!audio" />
          </button>
          <button type="button" class="btn btn-link" @click="$emit('toggleVideo')" id="video-c">
            <video-icon title="" class="option-icon" v-if="video" />
            <video-off-icon title="" :class="['option-icon', { 'disabled-icon': !canAddVideo }, { 'icon-danger': canAddVideo }]" v-if="!video" />
          </button>
          <div class="horizontal-rule"></div>
          <button type="button" class="btn btn-link" id="screen-share-c" v-if="!screenShared" @click="onShareScreen">
            <monitor-icon class="tab-icon" />
          </button>
          <button type="button" class="btn btn-primary" id="screen-share-c" v-else @click="onUnshareScreen">
            <monitor-icon class="tab-icon" />
          </button>
        </div>
        <div v-if="participantOnly">
          <button type="button" class="btn btn-link" id="publish-c" @click="$emit('publish')">
            <phone-icon class="tab-icon" />
          </button>
        </div>
        <div class="horizontal-rule"></div>
        <button type="button" class="btn btn-link" @click="$emit('leave')" id="leave-c">
          <exit-run-icon title="" class="option-icon" />
        </button>
        <div class="horizontal-rule"></div>
        <button type="button" class="btn btn-link" id="collapse-btn-c" @click="collapseControlPanelHandler()">
          <chevron-left-icon class="option-icon" />
        </button>
      </div>
      <!-- TOOLTIPS -->
      <b-tooltip placement="left" target="participant-list-c" triggers="hover" :title="$t('participantList')" />
      <b-tooltip placement="left" target="chat-c" triggers="hover" :title="$t('chat.chat')" />
      <b-tooltip placement="left" target="case-list-c" triggers="hover" :title="$t('caseList')" />
      <b-tooltip placement="left" target="settings-c" triggers="hover" :title="$t('settings')" />
      <b-tooltip placement="left" target="network-strength-c" triggers="hover" :title="currentRoundTripTime * 1000 + ' ms'" />
      <b-tooltip placement="left" target="audio-c" triggers="hover" :title="audio ? $t('conference.muteAudio') : $t('conference.unmuteAudio')" />
      <b-tooltip placement="left" target="video-c" triggers="hover" :title="tooltipVideo" />
      <b-tooltip placement="left" target="screen-share-c" triggers="hover">
        <span v-if="!screenShared">
          {{ $t("conference.shareScreen") }}
        </span>
        <span v-else>
          {{ $t("conference.unshareScreen") }}
        </span>
      </b-tooltip>
      <b-tooltip placement="left" target="leave-c" triggers="hover" :title="$t('conference.leaveConference')" />
      <b-tooltip placement="left" target="collapse-btn-c" triggers="hover" :title="$t('uncollapse')" />
      <b-tooltip placement="left" target="publish-c" triggers="hover" :title="$t('dialIn')" />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AccountMultipleIcon from "vue-material-design-icons/AccountMultiple";
import ExitRunIcon from "vue-material-design-icons/ExitRun";
import ForumIcon from "vue-material-design-icons/Forum";
import MonitorIcon from "vue-material-design-icons/Monitor";
import FormatListCheckboxIcon from "vue-material-design-icons/FormatListCheckbox";
import MicrophoneIcon from "vue-material-design-icons/Microphone";
import MicrophoneOffIcon from "vue-material-design-icons/MicrophoneOff";
import NetworkStrength1AlertIcon from "vue-material-design-icons/NetworkStrength1Alert";
import NetworkStrength1Icon from "vue-material-design-icons/NetworkStrength1";
import NetworkStrength2Icon from "vue-material-design-icons/NetworkStrength2";
import NetworkStrength3Icon from "vue-material-design-icons/NetworkStrength3";
import NetworkStrength4Icon from "vue-material-design-icons/NetworkStrength4";
import OpenInNewIcon from "vue-material-design-icons/OpenInNew";
import PhoneIcon from "vue-material-design-icons/Phone";
import CogIcon from "vue-material-design-icons/Cog";
import VideoIcon from "vue-material-design-icons/Video";
import VideoOffIcon from "vue-material-design-icons/VideoOff";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight";
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft";
import Chat from "./Chat";
import ParticipantList from "./ParticipantList";
import config from "../config/config";
import { uniqBy } from "lodash";
import { markdownToHtml } from "@/util/util";

export default {
  props: {
    showEntries: {
      type: Boolean,
      default: true
    },

    entries: {
      type: Array,
      default() {
        return [];
      }
    },

    consentDialogScreen: {
      type: Boolean,
      defaulft: false
    },

    room: {
      type: Number
    },

    audio: {
      type: Boolean,
      default: true
    },

    video: {
      type: Boolean,
      default: false
    },

    speaking: {
      type: Boolean,
      default: false
    },

    devices: {
      type: Array,
      default() {
        return [];
      }
    },

    currentRoundTripTime: {
      type: Number,
      default: 0
    },

    screenShared: {
      type: Boolean,
      default: false
    },

    fullScreenParticipant: {
      type: Object,
      default: null
    },

    numberOfVideos: {
      type: Number,
      default: 0
    },

    maxNumberOfVideos: {
      type: Number,
      default: 8
    },

    participantOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      connection: null,
      connected: false,
      socketUrl: `${config.WEBSOCKET_URL}/message/${this.room}`,
      messages: [],
      newMessages: 0,
      participants: [],
      tabIndex: 0,
      pingInterval: 15000,
      pingIntervalHandle: null,
      typingText: "",
      error: null,
      audioDevice: "default",
      collapseControlPanel: false
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token,
      fullUserName: state => state.authentication.keycloak.idTokenParsed.name,
      subject: state => state.authentication.keycloak.subject,
      keycloak: state => state.authentication.keycloak
    }),

    demo() {
      return config.DEMO;
    },

    filteredEntries() {
      if (this.entries) {
        return this.entries.filter(entry => entry);
      } else {
        return [];
      }
    },

    deactivateDocumentation() {
      return config.DEACTIVATE_DOCUMENTATION;
    },

    audioDevices() {
      if (!this.devices) {
        return [];
      }
      return uniqBy(
        this.devices.filter(device => device.kind === "audioinput"),
        "groupId"
      );
    },

    canAddVideo() {
      return this.numberOfVideos < this.maxNumberOfVideos;
    },

    tooltipVideo() {
      if (this.video) {
        return this.$t("conference.muteVideo");
      } else {
        if (this.canAddVideo) {
          return this.$t("conference.unmuteVideo");
        } else {
          return this.$t("noMoreVideoStreamsAllowed");
        }
      }
    }
  },

  methods: {
    collapseControlPanelHandler(tab) {
      this.collapseControlPanel = !this.collapseControlPanel;
      switch (tab) {
        case "chat":
          this.$refs.tabChat.activate();
          break;
        case "case":
          this.$refs.tabCase.activate();
          break;
        case "settings":
          this.$refs.tabSettings.activate();
          break;
        default:
          this.$refs.tabParticipants.activate();
      }
    },
    sendMessage(message) {
      this.connection.send(
        JSON.stringify({
          type: "MESSAGE",
          message
        })
      );
    },

    sendStartTyping() {
      this.connection.send(
        JSON.stringify({
          type: "TYPING_START"
        })
      );
    },

    sendStopTyping() {
      this.connection.send(
        JSON.stringify({
          type: "TYPING_END"
        })
      );
    },

    sendStartSpeaking() {
      this.connection.send(
        JSON.stringify({
          type: "TYPING_START",
          message: "SPEAKING_START"
        })
      );
    },

    sendStopSpeaking() {
      this.connection.send(
        JSON.stringify({
          type: "TYPING_START",
          message: "SPEAKING_END"
        })
      );
    },

    sendAdminMute(id) {
      this.connection.send(
        JSON.stringify({
          type: "TYPING_START",
          message: "MODERATOR_MUTE_" + id
        })
      );
    },

    ping() {
      this.connection.send(
        JSON.stringify({
          type: "PING"
        })
      );
    },

    connect() {
      let params = new URLSearchParams();
      if (this.token) {
        params.append("access_token", this.token);
      }
      this.connection = new WebSocket(`${this.socketUrl}?${params}`);
      this.connection.onopen = this.onopen;
      this.connection.onclose = this.onclose;
      this.connection.onmessage = this.onmessage;
      this.connection.onerror = this.onerror;
    },

    onownmessage(message) {
      this.sendMessage(message);
    },

    onopen() {
      this.connected = true;
    },

    onclose() {
      this.connected = false;
      setTimeout(this.connect, 10);
    },

    onmessage(e) {
      let message = JSON.parse(e.data);

      switch (message.type) {
        case "MESSAGE":
          message.html = markdownToHtml(message.message, true);
          this.messages.push(message);
          if (this.tabIndex !== 1) {
            this.newMessages++;
          }
          break;
        case "PARTICIPANT_LIST":
          this.participants = message.payload
            ? message.payload.map(p => {
                p.speaking = false;
                p.typing = false;
                return p;
              })
            : [];
          break;
        case "TYPING_START": {
          switch (message.message) {
            case "SPEAKING_START": {
              let participant = this.participants.find(p => p.userId === message.fromId);
              if (participant) {
                participant.speaking = true;
              }
              break;
            }
            case "SPEAKING_END": {
              let participant = this.participants.find(p => p.userId === message.fromId);
              if (participant) {
                participant.speaking = false;
              }
              break;
            }
          }
          if (message.message != null && message.message.startsWith("MODERATOR_MUTE")) {
            const id = message.message.substring(message.message.lastIndexOf("_") + 1);
            if (id != null && id.includes(this.subject)) {
              this.$emit("mute");
            }
          }
          if (message.fromId !== this.subject && !message.hasOwnProperty("message")) {
            this.typingText = message.from;
          }
          break;
        }
        case "TYPING_END":
          if (message.fromId !== this.subject) {
            this.typingText = "";
          }
          break;
        case "SPEAKING_START":
          // console.log(message);
          break;
        case "SPEAKING_END":
          // console.log(message);
          break;
        case "ERROR":
          if (message.payload === "invalid room") {
            this.error = this.$t("error.noConnectionToChat");
            clearInterval(this.pingIntervalHandle);
            this.connection.onclose = () => {};
            this.connection.close();
          } else {
            this.error = message.payload;
          }
      }
    },

    onerror(error) {
      console.error("WebSocket Error ", error);
    },

    onShareScreen() {
      this.$emit("shareScreen");
    },

    onUnshareScreen() {
      this.$emit("unshareScreen");
    }
  },

  mounted() {
    this.connect();
  },

  beforeDestroy() {
    if (this.connection) {
      clearInterval(this.pingIntervalHandle);
      this.connection.onclose = () => {};
      this.connection.close();
    }
  },

  watch: {
    tabIndex(newValue) {
      if (newValue === 1) {
        this.newMessages = 0;
      }
    },

    speaking(newValue) {
      if (newValue) {
        this.sendStartSpeaking();
      } else {
        this.sendStopSpeaking();
      }
    },

    audioDevice(newValue) {
      this.$emit("audioDeviceChanged", newValue);
    }
  },

  components: {
    AccountMultipleIcon,
    Chat,
    CogIcon,
    ExitRunIcon,
    FormatListCheckboxIcon,
    ForumIcon,
    MicrophoneIcon,
    MicrophoneOffIcon,
    MonitorIcon,
    NetworkStrength1AlertIcon,
    NetworkStrength1Icon,
    NetworkStrength2Icon,
    NetworkStrength3Icon,
    NetworkStrength4Icon,
    OpenInNewIcon,
    PhoneIcon,
    ParticipantList,
    VideoIcon,
    VideoOffIcon,
    ChevronRightIcon,
    ChevronLeftIcon
  }
};
</script>

<style lang="scss" scoped>
.tab-icon {
  font-size: 1.2rem;
}

.tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.tab-pane {
  margin: 0;
  padding: 0;
  height: 100%;
}

.moderator-panel {
  padding: 15px;
}

.cases-panel {
  padding: 15px;
  flex: 1;
}

.error-panel {
  padding: 15px;
  background: $vitu-danger;
  color: white;
}

.participant-list {
  flex: 1;
}

.collapse-panel {
  border-top: 1px solid $border-color;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-right: 1.5rem;
}
.collapse-panel-content {
  display: flex;
  float: right;
  font-size: 1.2rem;
}

.options-panel {
  border-top: 1px solid $border-color;
  padding: 3px;
  display: flex;
  justify-content: center;
}
@media only screen and (min-height: 521px) {
  .options-panel-collapsed {
    padding: 3px;
    position: fixed;
    bottom: 0;
  }
}
@media only screen and (max-height: 520px) {
  .options-panel-collapsed {
    padding: 3px;
    margin-top: auto;
  }
}

.option-icon {
  font-size: 1.4rem;
}

.disabled-icon {
  color: grey;

  &:hover,
  &:focus {
    color: grey;
  }
}

.network-strength-icon {
  @extend .option-icon;
  color: $vitu-green;
}

.network-strength-alert-icon {
  @extend .option-icon;
  color: $vitu-danger;
}

.option-info {
  padding: 0.375rem 0.75rem;
}

.screen-share-dropup {
  width: 250px;
  padding: 0.25rem 1.5rem;
}

.tab-container {
  border-radius: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow: hidden;
}

.vertical-rule {
  border-left: 1px solid $border-color;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  margin-top: -3px;
  margin-bottom: -6px;
}
.horizontal-rule {
  border-top: 1px solid $border-color;
  margin: 0.25rem -0.25rem 0.25rem -0.2rem;
}

.icon-danger {
  color: $vitu-danger;
}
</style>

<style lang="scss">
.tab-container {
  .card-header {
    background: white;
  }

  .tab-content {
    overflow-y: auto;
    flex: 1;
  }
}
</style>
