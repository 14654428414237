import getEnv from "@/config/env";

const config = {
  AUTHENTICATION: process.env.VUE_APP_AUTHENTICATION === "true",
  BASE_URL: getEnv("VUE_APP_BASE_URL"),
  FHIR_URL: getEnv("VUE_APP_FHIR_URL"),
  FHIR_URL_EXTERNAL: getEnv("VUE_APP_FHIR_URL_EXTERNAL") ? getEnv("VUE_APP_FHIR_URL_EXTERNAL") : getEnv("VUE_APP_FHIR_URL"),
  FHIR_ORGANIZATION: getEnv("VUE_APP_FHIR_ORGANIZATION") ? getEnv("VUE_APP_FHIR_ORGANIZATION") : "",
  ENABLE_CDS: getEnv("VUE_APP_ENABLE_CDS") === "true",
  CDS_URL: getEnv("VUE_APP_CDS_URL"),
  KEYCLOAK_URL: getEnv("VUE_APP_KEYCLOAK_URL"),
  KEYCLOAK_REALM: getEnv("VUE_APP_KEYCLOAK_REALM"),
  KEYCLOAK_CLIENT_ID: getEnv("VUE_APP_KEYCLOAK_CLIENT"),
  JANUS_URL: getEnv("VUE_APP_JANUS_URL"),
  VIDEO_BACKEND_URL: getEnv("VUE_APP_VIDEO_BACKEND_URL"),
  WEBSOCKET_URL: getEnv("VUE_APP_WEBSOCKET_URL"),
  VITU_PROCESS_URL: getEnv("VUE_APP_VITU_PROCESS_URL"),
  FHIR_SERVICES_URL: getEnv("VUE_APP_FHIR_SERVICES_URL"),
  SECURITY_URL: getEnv("VUE_APP_SECURITY_URL"),
  DEMO: process.env.VUE_APP_DEMO === "true",
  DEFAULT_CONFERENCE_SETTINGS: {
    showVideo: true,
    enableAudioInitially: true,
    bitrate: 256,
    aspectRatio: "16:10",
    cutVideoStreams: true,
    persistentRoomEnabled: true,
    persistentRoomName: "Allgemeiner Konferenzraum",
    showDateTimeInTitle: true,
    maxNumberOfVideos: 8
  },
  SUCCESS_HEADER_TIMEOUT: 10000,
  ADHOC_ROOM_DESCRIPTION: "fc2d99de-29fe-4d08-8dc8-92167876551a",
  MAX_LENGTH_CONFERENCE_NAME: 50,
  DEACTIVATE_DOCUMENTATION: process.env.VUE_APP_DEACTIVATE_DOCUMENTATION === "true",
  VITU_BRANDING: process.env.VUE_APP_VITU_BRANDING !== "false",
  PREVENT_SELECT: process.env.VUE_APP_PREVENT_SELECT !== "false",
  INFO_LINK: getEnv("VUE_APP_INFO_LINK"),
  SHOW_INFO_LINK: process.env.VUE_APP_SHOW_INFO_LINK === "true",
  DATENSPENDE_ENABLED: getEnv("VUE_APP_DATENSPENDE_ENABLED"),
  ADT_IMPORT_ENABLED: process.env.VUE_APP_ADT_IMPORT_ENABLED === "true",
  ADT_BACKEND_URL: getEnv("VUE_APP_ADT_BACKEND_URL"),
  MATOMO_ENABLED: process.env.VUE_APP_MATOMO_ENABLED === "true",
  MATOMO_URL: process.env.VUE_APP_MATOMO_URL,
  MATOMO_SITE_ID: process.env.VUE_APP_MATOMO_SITE_ID,
  DATA_IMPORTER_URL: getEnv("VUE_APP_DATA_IMPORTER_URL")
};

export default config;
